
import * as React from 'react';
import PropTypes from 'prop-types';
import BasicDataMutationForm from '../../../infrastructure/data/BasicDataMutationForm';
import { useRegisterNatureOfInterestMutation } from '../../../api'
import { isInterestedValueTrue } from './RegisterInterestInterestedInput';
import { getDetailsFieldName, getInterestedFieldName } from '../fields';
import SaleTypes from '../../maintenance/sale-types/SaleTypes';

const createInterest = (formData, saleType) => ({
  interested: isInterestedValueTrue(formData[getInterestedFieldName(saleType)]),
  details: formData[getDetailsFieldName(saleType)]
})

const RegisterInterestForm = ({ packageId, formContext, onSubmit, children }) => {
  const [registerInterest, result] = useRegisterNatureOfInterestMutation();

  const handleSubmit = async (data) => {
    const interest = {
      id: packageId,
      assignment: createInterest(data, SaleTypes.Assignment),
      exclusiveLicense: createInterest(data, SaleTypes.ExclusiveLicense),
      nonExclusiveLicense: createInterest(data, SaleTypes.NonExclusiveLicense),
    };
    
    await registerInterest(interest).unwrap();

    if (onSubmit) {
      onSubmit();
    }
  }

  return (
    <BasicDataMutationForm
      mutationResult={result}
      formContext={formContext}
      onSubmit={handleSubmit}>
      {children}
    </BasicDataMutationForm>
  );
}

RegisterInterestForm.propTypes = {
  packageId: PropTypes.string.isRequired,
  formContext: PropTypes.shape(),
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default RegisterInterestForm;