import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const ImageViewer = ({ imageUrl, open, onClose }) => (
    <Modal
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
        border={false}
        open={open}
        onClose={onClose}
        closeAfterTransition
        disableAutoFocus
      >
        <Fade
            in={open}
            timeout={500}
        >
          <img
            src={imageUrl}
            alt="asset"
            sx={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
      </Modal>
);

ImageViewer.propTypes = {
    imageUrl: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ImageViewer;