import * as React from 'react';
import Sections from '../../infrastructure/layout/sections/Sections';
import Section from '../../infrastructure/layout/sections/Section';
import SectionTitle from '../../infrastructure/text/SectionTitle';
import CentreBox from '../../infrastructure/layout/CentreBox';
import LogoutButton from './LogoutButton';

const NewUserPageContent = () => {
  return (
      <Sections>
        <Section>
          <SectionTitle>Account pending activation</SectionTitle>
          <CentreBox>Your account will be reviewed and we will be in touch soon.  If you have any questions in the meantime please contact&nbsp;<a href='mailto:hello@agoraip.co.uk?subject=Agora IP Account Activation'>hello@agoraip.co.uk</a>.</CentreBox>
          <CentreBox><LogoutButton /></CentreBox>
        </Section>
      </Sections>
  );
}

export default NewUserPageContent;