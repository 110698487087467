
import * as React from 'react';
import PropTypes from 'prop-types';
import NoInterestInformation from './NoInterestInformation';
import RegisterInterestButton from './RegisterInterestButton';
import VerticalLayout from '../../../infrastructure/layout/VerticalLayout';

const NoInterestSection = ({ packageId }) =>  (
  <VerticalLayout>
    <NoInterestInformation />
    <RegisterInterestButton packageId={packageId} />    
  </VerticalLayout>
);

NoInterestSection.propTypes = {
  packageId: PropTypes.string.isRequired
};

export default NoInterestSection;