export const maxLengthRule = (maxLength) => ({ value: maxLength, message: `must be ${maxLength} characters or less` });

export const maxIntRule = (value) => parseFloat(value) < 2147483647 || `The amount is too big`;

export const positiveNumberRule = (value) => parseFloat(value) > 0 || 'Should be a valid amount';

export const setRequiredTextOnValidationIfRequired = (validation, required) => {
    if (required && !validation.required) {
        validation.required = 'This field is required';
    }
}