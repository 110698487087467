
import * as React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const MaintainIpPackageItemGrid = ({ children }) => <Grid container spacing={2}>{children}</Grid>

MaintainIpPackageItemGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MaintainIpPackageItemGrid;