
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Item = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ItemCloudItem = ({ children }) => (
  <Item>{children}</Item>
);
  
ItemCloudItem.propTypes = {
  children: PropTypes.node
};

export default ItemCloudItem;