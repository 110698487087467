import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Visible from '../../../infrastructure/Visible';
import SelectedBidStatus from '../SelectedBidStatus';

const PlaceBidButtonsVisibility = ({ children, selectedBid }) => {
    const formContext = useFormContext();

    return (
        <Visible isVisible={selectedBid.status === SelectedBidStatus.New || formContext.formState.isDirty}>{children}</Visible>
    );
}

PlaceBidButtonsVisibility.propTypes = {
    selectedBid: PropTypes.shape().isRequired,
    children: PropTypes.node.isRequired,
};

export default PlaceBidButtonsVisibility;