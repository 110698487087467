
import * as React from 'react';
import AuthenticatedPage from '../users/authentication/AuthenticatedPage';
import Sections from '../infrastructure/layout/sections/Sections';
import IpPackagesSection from './IpPackagesSection';
import AppDrawerItems from '../AppDrawerItems';

const IpPackageListPage = () => {
  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
          <IpPackagesSection />
      </Sections>
    </AuthenticatedPage>
  );
}

export default IpPackageListPage;