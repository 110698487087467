import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Cancel } from '@mui/icons-material';

const CancelButton = ({ id, disabled, onClick }) => (
    <Button
        id={id}
        fullWidth
        variant="contained"
        disabled={disabled}
        onClick={onClick}
    >
        <Cancel />&nbsp;Cancel
    </Button>
);

CancelButton.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

CancelButton.defaultProps = {
    disabled: false,
};

export default CancelButton;