import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormValues } from '../../../../infrastructure/forms/hooks';
import StandardOptionAutoCompleteFormField from '../../../../infrastructure/forms/StandardOptionAutoCompleteFormField';
import { useGetAllowedJurisdictionsSelectionTypeOptions, useGetAllowedApplicableJurisdictions } from '../redux';
import * as fields from '../fields';
import { useFormContext } from 'react-hook-form';
import JurisdictionSelectionTypes from '../../../../jurisdictions/JurisdictionSelectionTypes';
import { makeListCommaSeparated } from '../../../../infrastructure/helpers';


const JurisdictionsSelectionTypeInput = ({ packageId }) => {
  const formValues = useFormValues();
  const { setValue } = useFormContext();
  const jurisdictionTypes = useGetAllowedJurisdictionsSelectionTypeOptions(packageId, formValues[fields.bidTypeFieldName]);
  const allowedJurisdictions = useGetAllowedApplicableJurisdictions(packageId, formValues[fields.bidTypeFieldName]);

  const onValueChange = value => {
    if(value === JurisdictionSelectionTypes.All) {
      setValue(fields.applicableJurisdictionsFieldName, makeListCommaSeparated(allowedJurisdictions));
    }
  } 

  return (
    <StandardOptionAutoCompleteFormField
      id='jurisdictions-selection-type-input'
      name={fields.jurisdictionsSelectionTypeFieldName}
      options={jurisdictionTypes}
      onValueChange={onValueChange}
      required
      label="Jurisdictions"
      fullWidth
    />
  )
};

JurisdictionsSelectionTypeInput.propTypes = {
  packageId: PropTypes.string.isRequired
}

export default JurisdictionsSelectionTypeInput;