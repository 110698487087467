import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setFilteredData } from './redux/slices';
import StandardTextFilter from '../../infrastructure/data/filtering/StandardTextFilter';

const UserFilter = ({ data }) => {
  const dispatch = useDispatch();

  const onFilterSet = (filterText) => {
    const filtered = filterText 
      ? data.filter(d => 
        (d.name && d.name.toLowerCase().includes(filterText.toLowerCase()))
        || (d.email && d.email.toLowerCase().includes(filterText.toLowerCase()))
        || (d.organisation && d.organisation.toLowerCase().includes(filterText.toLowerCase())))
      : null;
    dispatch(setFilteredData({ filteredData: filtered }));
  };

  return (
    <StandardTextFilter
      label='Name/Email/Org'
      onFilterSet={onFilterSet}
    />
  );
}

UserFilter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default UserFilter;