
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../../../infrastructure/layout/tabs/TabPanel';
import ItemCloud from '../../../../infrastructure/layout/ItemCloud';
import MaintainIpPackageLargeGridItem from '../../MaintainIpPackageLargeGridItem'
import IpPackageFileNameSection from '../IpPackageFileNameSection';
import { useUploadPackFileMutation } from '../../../../api';
import { useGetPackagePackFileName } from '../../redux/selectors';

const MaintainIpPackageItemPacksTab = ({ packageId, isNew, currentTabIndex }) =>  {
  const fileName = useGetPackagePackFileName(packageId);
  const [uploadFile] = useUploadPackFileMutation();

  return (
    <TabPanel
      currentIndex={currentTabIndex}
      index={3}
    >
      <MaintainIpPackageLargeGridItem>
      <ItemCloud>
        <IpPackageFileNameSection
          packageId={packageId}
          fileName={fileName}
          onUploadFile={uploadFile}
          isNew={isNew}
          acceptedFileTypes=".zip"
        />
      </ItemCloud>  
      </MaintainIpPackageLargeGridItem>
    </TabPanel>
  );
}

MaintainIpPackageItemPacksTab.propTypes = {
  packageId: PropTypes.string.isRequired,  
  isNew: PropTypes.bool,  
  currentTabIndex: PropTypes.number.isRequired,
};

export default MaintainIpPackageItemPacksTab;