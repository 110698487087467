const SaleTypes = {
    Assignment: 'assignment',
    ExclusiveLicense: 'exclusiveLicense',
    NonExclusiveLicense: 'nonExclusiveLicense',
}

export const getSaleTypeLabel = (saleType) => {
    switch(saleType) {
        case SaleTypes.Assignment:
            return 'Assignment';
        case SaleTypes.ExclusiveLicense:
            return 'Exclusive license';
        case SaleTypes.NonExclusiveLicense:
            return 'Non-exclusive license';
        default:
            return `UNKNOWN sale type: ${saleType}` 
    }
};

export const parseSaleType = (rawType) => SaleTypes[rawType];

export const saleTypeToServiceFormat = (saleType) => {
    switch(saleType) {
        case SaleTypes.Assignment:
            return 'Assignment';
        case SaleTypes.ExclusiveLicense:
            return 'ExclusiveLicense';
        case SaleTypes.NonExclusiveLicense:
            return 'NonExclusiveLicense';
        default:
            return `UNKNOWN sale type: ${saleType}` 
    }
};

export const getAllSaleTypeOptions = () => {
    const result = [];
    const keys = Object.keys(SaleTypes);
    for (let key of keys) {
        result.push({
            label: getSaleTypeLabel(SaleTypes[key]),
            value: SaleTypes[key]
        });
    }
    return result;
}

export default SaleTypes;