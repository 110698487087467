import * as uuid from 'uuid';
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { boolToYesNo } from '../../infrastructure/helpers';
import { getPackageById } from '../redux/selectors'
import { getDetailsFieldName, getInterestedFieldName } from './fields';
import SaleTypes, { getSaleTypeLabel } from '../maintenance/sale-types/SaleTypes';

const getHasInterest = asset => asset.registeredInterest !== undefined && asset.registeredInterest !== null;
const getHasRegisteredInterestStatus = (asset, status) => getHasInterest(asset) && asset.registeredInterest.status && asset.registeredInterest.status === status

const getHasNoInterest = id => createSelector(
  getPackageById(id),
  asset => !getHasInterest(asset)
);

const getHasInterestRegistered = id => createSelector(
  getPackageById(id),
  asset => getHasRegisteredInterestStatus(asset, 'Registered')
);

const getHasNatureOfInterestSubmitted = id => createSelector(
  getPackageById(id),
  asset => getHasRegisteredInterestStatus(asset, 'NatureOfInterestSubmitted')
);

const createInterestRow = (saleType, interest) => ({
  id: uuid.v4(),
  name: getSaleTypeLabel(saleType),
  interested: boolToYesNo(interest.interested),
  extraDetails: interest.details
});

export const getInterest = id => createSelector(
  getPackageById(id),
  asset => [
    createInterestRow(SaleTypes.Assignment, asset.registeredInterest.assignment),
    createInterestRow(SaleTypes.ExclusiveLicense, asset.registeredInterest.exclusiveLicense),
    createInterestRow(SaleTypes.NonExclusiveLicense, asset.registeredInterest.nonExclusiveLicense)
  ]
);

export const getInterestMappedToFormValues = id => createSelector(
  getPackageById(id),
  asset => {
    const ri = asset.registeredInterest;
    return {
      [getInterestedFieldName(SaleTypes.Assignment)]: boolToYesNo(ri.assignment.interested),
      [getDetailsFieldName(SaleTypes.Assignment)]: ri.assignment.details,
      [getInterestedFieldName(SaleTypes.ExclusiveLicense)]: boolToYesNo(ri.exclusiveLicense.interested),
      [getDetailsFieldName(SaleTypes.ExclusiveLicense)]: ri.exclusiveLicense.details,
      [getInterestedFieldName(SaleTypes.NonExclusiveLicense)]: boolToYesNo(ri.nonExclusiveLicense.interested),
      [getDetailsFieldName(SaleTypes.NonExclusiveLicense)]: ri.nonExclusiveLicense.details
    }
  }
);

export const useGetHasNoInterest = id => useSelector(getHasNoInterest(id));
export const useGetHasInterestRegistered = id => useSelector(getHasInterestRegistered(id));
export const useGetHasNatureOfInterestSubmitted = id => useSelector(getHasNatureOfInterestSubmitted(id));
export const useGetInterest = id => useSelector(getInterest(id));
export const useGetInterestMappedToFormValues = id => useSelector(getInterestMappedToFormValues(id));