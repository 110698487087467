import * as React from 'react';
import PropTypes from 'prop-types';
import BackButton from '../../../infrastructure/data/BackButton';

const BidBackButton = ({ onBack }) => (
    <BackButton id="edit-bid-back" onClick={onBack} />
);

BidBackButton.propTypes = {
    onBack: PropTypes.func.isRequired,
};

export default BidBackButton;