
import * as React from 'react';
import * as uuid from 'uuid';
import AuthenticatedPage from '../../users/authentication/AuthenticatedPage';
import Sections from '../../infrastructure/layout/sections/Sections';
import AppDrawerItems from '../../AppDrawerItems';
import MaintainIpPackagesSection from './MaintainIpPackagesSection';
import MaintainIpPackageItemSection from './MaintainIpPackageItemSection';
          
const MaintainIpPackagePage = () => {
  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
        <MaintainIpPackagesSection />
        <MaintainIpPackageItemSection
          packageId={uuid.v4()}
          isNew
        />
      </Sections>
    </AuthenticatedPage>
  );
}

export default MaintainIpPackagePage;