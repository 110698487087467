import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { makeListCommaSeparated } from '../../../infrastructure/helpers'
import { ipApi } from '../../../api';

const selectPackageByIdResult = (id) => ipApi.endpoints.getMaintainablePackageById.select(id);

const emptyPackage = () => ({ 
  name: '',
  description: '',
  proprietor: '',
  tags: [],
  saleTypesConfiguration: {
    assignment: emptySalesType,
    exclusiveLicense: emptySalesType,
    nonExclusiveLicense: emptySalesType,
    readOnly: false,
  },
  statuses: {
  }
});

export const getRawPackageById = id => createSelector(
  selectPackageByIdResult(id),
  assetResult => assetResult?.data ?? emptyPackage()
);

const emptySalesType = {
  useAllJurisdictions: false,
  useSelectedJurisdictions: false,
  applicableJurisdictions: []
};

export const getPackageById = id => createSelector(
  getRawPackageById(id),
  ipPackage => ({ 
    ...ipPackage,
    tags: makeListCommaSeparated(ipPackage.tags)
  })
);

export const getPackagePackFileName = id => createSelector(
  getRawPackageById(id),
  p => p.packFileName
);

export const getPackagePictureFileName = id => createSelector(
  getRawPackageById(id),
  p => p.pictureFileName
);

export const getSaleTypesConfigurationIsReadOnly = id => createSelector(
  getRawPackageById(id),
  p => p.saleTypesConfiguration.readOnly
);

export const useGetPackageById = id => useSelector(getPackageById(id));
export const useGetPackagePackFileName = id => useSelector(getPackagePackFileName(id));
export const useGetPackagePictureFileName = id => useSelector(getPackagePictureFileName(id));
export const useGetSaleTypesConfigurationIsReadOnly = id => useSelector(getSaleTypesConfigurationIsReadOnly(id));
