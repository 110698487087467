
import * as React from 'react';
import PropTypes from 'prop-types';
import ImageDisplay from '../../infrastructure/ImageDisplay';
import { getIpPackagePictureFile } from '../../api';

const PackagePictureDisplay = ({ packageId, fileName }) => (
  <ImageDisplay
    id="ip-package-picture-display"
    fileName={fileName}
    getPictureFile={async () => await getIpPackagePictureFile(packageId)}
  />
);

PackagePictureDisplay.propTypes = {
  packageId: PropTypes.string.isRequired,
  fileName: PropTypes.string,
};

export default PackagePictureDisplay;