
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'
import CopyrightIcon from '@mui/icons-material/Copyright';

const ProprietorChip = ({ label }) => <Chip icon={<CopyrightIcon />} label={label} />;

ProprietorChip.propTypes = {
    label: PropTypes.string.isRequired
};

export default ProprietorChip;