import * as React from 'react';

import TagFilterPopup from './TagFilterPopup';
import MinimalTagCloud from './MinimalTagCloud';
import FullTagCloud from './FullTagCloud';

const TagFilterSection = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
   
  return (
    <React.Fragment>
      <TagFilterPopup
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <FullTagCloud />
      </TagFilterPopup>
      <MinimalTagCloud onSelectTags={() => setDialogOpen(true)}/>
    </React.Fragment>
  );
}

export default TagFilterSection;