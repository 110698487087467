
import * as React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const MaintainIpPackageLargeGridItem = ({ children }) => <Grid item xs={12}>{children}</Grid>;

MaintainIpPackageLargeGridItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MaintainIpPackageLargeGridItem;