
import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../../infrastructure/Visible';
import { useGetSaleTypesConfigurationIsReadOnly } from '../redux/selectors';

const ReadOnlySaleTypesVisibility = ({ packageId, children }) =>  {
  const isPackageReadOnly = useGetSaleTypesConfigurationIsReadOnly(packageId);
  return (
    <Visible isVisible={isPackageReadOnly}>
      {children}
    </Visible>
  );
}

ReadOnlySaleTypesVisibility.propTypes = {
  packageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ReadOnlySaleTypesVisibility;

