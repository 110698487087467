import * as React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ErrorDisplay = ({ title }) => { 
  return (
    <Snackbar open={true}>
      <Alert severity="error">
        { title }
      </Alert>
    </Snackbar>
  );
  }

ErrorDisplay.propTypes = {
  title: PropTypes.string.isRequired
};

export default ErrorDisplay;