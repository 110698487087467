import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

const FormContainer = ({ 
    formContext,
    onSuccess,
    children,
    ...useFormProps
}) => {
  const form = useForm({
    ...useFormProps,
  });

  if (!formContext) {        
    formContext = form;    
  }
      
  return (
    <FormProvider {...formContext}>
      <form
        noValidate
        onSubmit={formContext.handleSubmit(onSuccess)}
      >
        {children}
      </form>
    </FormProvider>
  );
}

FormContainer.propTypes = {
  formContext: PropTypes.shape(),
  onSuccess: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

FormContainer.defaultProps = {
  formContext: undefined
};

export default FormContainer;