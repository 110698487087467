import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from "react-router-dom";
import DataQueryTable from '../../../infrastructure/data/DataQueryTable';
import { columnFormat } from '../../../infrastructure/data/column';
import { useGetFilteredBids } from './redux';
import { bidHistoryPath } from '../../../AppRoutes';
import { toLocaleNumericFormat } from '../../../infrastructure/helpers';

const BidsList = ({ response }) => {
  const { id } = useParams();
  const bids = useGetFilteredBids();
  let navigate = useNavigate();

  return (
    <DataQueryTable
      id="all-user-bid-list"
      queryResponse={response}
      data={bids}
      onRowClick={(row) => navigate(`${bidHistoryPath}/${row.id}`)}
      selectedRowId={id}
      columns={([{
          name: 'IP Package Name',
          rowLookup: row => row.packageName
        },
        {
          name: 'Date',
          format: columnFormat.DATE,
          rowLookup: row => row.date
        },
        {
          name: 'Name', 
          rowLookup: row => row.name
        },
        {
          name: 'Email',
          rowLookup: row => row.email
        },
        {
          name: 'Job Title',
          rowLookup: row => row.jobTitle
        },
        {
          name: 'Organisation',
          rowLookup: row => row.organisation
        },
        {
          name: 'Status',
          rowLookup: row => row.status
        },
        {
          name: 'Bid Type',
          rowLookup: row => row.bidType
        },
        {
          name: 'Amount',
          rowLookup: row => toLocaleNumericFormat(row.amount)
        },
        {
          name: 'Selection Type',
          rowLookup: row => row.jurisdictionsSelectionType
        },
        {
          name: 'Jurisdictions',
          rowLookup: row => row.applicableJurisdictions
        },
      ])}
    />
  );
}

BidsList.propTypes = {
  response: PropTypes.shape().isRequired
};

export default BidsList;