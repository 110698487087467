import * as React from 'react';
import { maxLengthRule } from '../../../infrastructure/forms/validation';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { noteFieldName } from './fields';

const NoteInput = () => {
  return (
    <TextFormField
      name={noteFieldName}
      label="Note"
      variant="outlined"
      validation={{
        maxLength: maxLengthRule(500)
      }}
      fullWidth
    />
  )
};

export default NoteInput;