import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Delete } from '@mui/icons-material';

const DeleteButton = ({ id, disabled, onClick, children, ...rest}) => (
    <Button
        id={id}
        fullWidth
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        {...rest}
    >
        <Delete />&nbsp;{children}
    </Button>
);

DeleteButton.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired
};

DeleteButton.defaultProps = {
    disabled: false,
};

export default DeleteButton;