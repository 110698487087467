
import * as React from 'react';
import PropTypes from 'prop-types';
import LinkedInChip from '../infrastructure/chips/LinkedInChip';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';

const UserLinkedInProfile = ({ user }) => (
  <ItemCloudItem key={user.proprietor}><LinkedInChip label={user.linkedInProfile ?? 'None'} /></ItemCloudItem>
);
  
UserLinkedInProfile.propTypes = {
  user: PropTypes.shape().isRequired
};

export default UserLinkedInProfile;