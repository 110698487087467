import React from 'react';
import ReduxProvider from './redux'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes from './AppRoutes';
import AuthenticationProvider from './users/authentication/AuthenticationProvider';
import AppLocalisation from './AppLocalisation';

const App = () => {

  return (<ReduxProvider>
    <Router>
      <AuthenticationProvider>
        <AppLocalisation>
          <AppRoutes />
        </AppLocalisation>
      </AuthenticationProvider>
    </Router>
  </ReduxProvider>
  )
};

export default App;