
import * as React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
  
const InformationAlert = ({ id, text }) => <Alert id={id} severity="info">{text}</Alert>

InformationAlert.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default InformationAlert;