import * as React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../infrastructure/data/DataTable';
import { useGetPackageBids } from '../redux';
import { getSaleTypeLabel } from '../../maintenance/sale-types/SaleTypes';
import { makeListCommaSeparated, toLocaleNumericFormat } from '../../../infrastructure/helpers';

const BidsList = ({ packageId, onSelectBid }) => {
  const bids = useGetPackageBids(packageId);
  return (
    <DataTable
      id="bid-list"
      isSelectable
      rows={bids}
      page={0}
      rowsPerPage={3}
      onRowClick={bid => onSelectBid(bid)}
      columns={([{
        name: 'Type',
        rowLookup: row => getSaleTypeLabel(row.bidType)
      },
      {
        name: 'Amount',
        rowLookup: row => toLocaleNumericFormat(row.amount)
      },
      {
        name: 'Jurisdictions',
        rowLookup: row => `${makeListCommaSeparated(row.applicableJurisdictions)}`
      },
      {
        name: 'Note',
        rowLookup: row => row.note
      }
      ])}
    />
  );
}

BidsList.propTypes = {
  packageId: PropTypes.string.isRequired,
  onSelectBid: PropTypes.func.isRequired
};

export default BidsList;



  