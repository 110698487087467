import * as React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../infrastructure/ActionButton'
import { useActivateUserMutation, useDeactivateUserMutation } from '../api';

const UserActivationButton = ({ user }) => {
  const [activateUser] = useActivateUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();
  const text = !user.activated ? "Activate User" : "Deactivate User";
  const toggleUserActivation = () => {
    if(!user.activated) {
      activateUser({ id: user.id }) 
    } else {
      deactivateUser({ id: user.id }) 
    }
  }
  return (
    <ActionButton 
      onClick={() => toggleUserActivation()}
    >
      {text}
    </ActionButton>
  );
}

UserActivationButton.propTypes = {
  user: PropTypes.shape().isRequired
};

export default UserActivationButton;