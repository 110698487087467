
import * as React from 'react';
import PropTypes from 'prop-types';
import SubmitOrCancelFormSection from '../../../infrastructure/forms/SubmitOrCancelFormSection';

const RegisterInterestButtons = ({ formContext, onCancel}) => {
  return (  
    <SubmitOrCancelFormSection
      id="nature-of-interest"
      title="Send Nature of Interest"
      formContext={formContext}
      onCancel={onCancel}
    />
  );
}

RegisterInterestButtons.propTypes = {
  formContext: PropTypes.shape().isRequired,
  onCancel: PropTypes.func
};

RegisterInterestButtons.defaultProps = {
  onCancel: undefined
};

export default RegisterInterestButtons;