import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../../../infrastructure/Visible';

const SelectableJurisdictionsVisibility = ({ jurisdictionsSelectionType, allowedJurisdictions, children }) => (
    <Visible
        isVisible={
            jurisdictionsSelectionType !== undefined
            && jurisdictionsSelectionType.length > 0
            && allowedJurisdictions.length > 0
        }
    >
        {children}
    </Visible>
);

SelectableJurisdictionsVisibility.propTypes = {
    jurisdictionsSelectionType: PropTypes.string,
    allowedJurisdictions: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
};

export default SelectableJurisdictionsVisibility;