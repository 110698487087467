
import * as React from 'react';
import PropTypes from 'prop-types';
import { getPackageStatusDescription } from '../../IpPackageStatuses'
import InformationAlert from '../../../infrastructure/InformationAlert';

const StatusDescription = ({ status }) => (
  <InformationAlert text={getPackageStatusDescription(status)} />
);

StatusDescription.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusDescription;