import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { ipApi } from '../api'
import { deepClone } from '../infrastructure/helpers';
import { getFilteredData } from './filtering/redux/selectors';

const selectUsersResult = ipApi.endpoints.getUsers.select();
const selectUserLogsByIdResult = (id) => ipApi.endpoints.getUserLogs.select(id);
const selectUserByIdResult = (id) => ipApi.endpoints.getUserById.select(id);

const convertUserLogEnumsToString = userLogs => {
  const mutableUserLogs = deepClone(userLogs);
  return mutableUserLogs.map(l => {
    if (isNaN(l.userLogType)) return l;
    
    let strEnum;
    switch (l.userLogType) {
      case 1:
        strEnum = 'Deactivated';
        break;
      case 2:
        strEnum = 'Activated';
        break;
      default:
        strEnum = 'Registered';
        break;
    }
    l.userLogType = strEnum;
    return l;
  });
};

const getUsers = createSelector(
  selectUsersResult,
  usersResult => {
    const result = usersResult?.data ?? { data: [] };
    return result.data;
  }
);

const getUserLogsById = id => createSelector(
  selectUserLogsByIdResult(id),
  userLogsResult => convertUserLogEnumsToString(userLogsResult?.data?.data ?? [])
);

export const getUserById = id => createSelector(
  selectUserByIdResult(id),
  userResult => userResult?.data ?? { id: '', name: '', email: '', organisation: '', jobTitle: '', role: '', telephoneNumber: '', linkedInProfile: '' }
);

const getFilteredUsers = createSelector(
  getUsers,
  getFilteredData,
  (users, filteredUsers) => filteredUsers ?? users
);

export const useGetUsers = () => useSelector(getUsers);
export const useGetUserById = id => useSelector(getUserById(id));
export const useGetUserLogsById = id => useSelector(getUserLogsById(id));
export const useGetFilteredUsers = () => useSelector(getFilteredUsers);