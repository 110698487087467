import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { max } from '../../../infrastructure/helpers';
import { useFormValues } from '../../../infrastructure/forms/hooks';
import { getStatusesFromForm, getActiveFromFieldName } from './fields';
import IpPackageStatuses from '../../IpPackageStatuses';
import DatePickerFormField from '../../../infrastructure/forms/DatePickerFormField';

const dateTooEarlyValidationError = (minimumDate) => ({
  type: 'custom',
  message: `must be later than ${minimumDate.format("DD/MM/YYYY")}`
});

const setErrorIfNeeded = (status, expectedStatus, statusValue, minimumDate, setError) => {
  if(status === expectedStatus && statusValue && statusValue < minimumDate) {
    setError(
      getActiveFromFieldName(status),
      dateTooEarlyValidationError(minimumDate));
  }
}

export const enforceDateOrder = (status, formValues, setError, clearErrors) => {
  clearErrors(getActiveFromFieldName(status));

  if(status === IpPackageStatuses.Draft) {
    return;
  }

  const statuses = getStatusesFromForm(formValues);  
  let minimumDate = new Date(0);
  minimumDate = max(statuses.draft, minimumDate);
  
  setErrorIfNeeded(status, IpPackageStatuses.Preview, statuses.preview, minimumDate, setError);
  minimumDate = max(statuses.preview, minimumDate);
  
  setErrorIfNeeded(status, IpPackageStatuses.Active, statuses.active, minimumDate, setError);
  minimumDate = max(statuses.active, minimumDate);
  
  setErrorIfNeeded(status, IpPackageStatuses.Closing, statuses.closing, minimumDate, setError);
  minimumDate = max(statuses.closing, minimumDate);
  
  setErrorIfNeeded(status, IpPackageStatuses.Closed, statuses.closed, minimumDate, setError);
};

const StatusActiveFromInput = ({ status }) =>  {
  const { setError, clearErrors } = useFormContext();
  const formValues = useFormValues();

  React.useEffect(() => {
    enforceDateOrder(status, formValues, setError, clearErrors);
  }, [status, formValues, setError, clearErrors]);
  
  return (
    <DatePickerFormField 
      id={`${status}-status-active-from-input`}
      name={getActiveFromFieldName(status)}
      label="Active from"
      variant="outlined"
    />
  );
}

StatusActiveFromInput.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusActiveFromInput;