import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const SubmitButton = ({ id, disabled, children }) => (
    <Button
        id={id}
        type="submit"
        fullWidth
        variant="contained"
        disabled={disabled}
    >
        {children}
    </Button>
);

SubmitButton.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired
};

SubmitButton.defaultProps = {
    disabled: false,
};

export default SubmitButton;