import PropTypes from 'prop-types';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import logger from "redux-logger";
import { ipApi } from './api'
import tagsReducer from './intellectual-property/filtering/redux/slices'
import assetsReducer from './intellectual-property/redux/slices'
import usersReducer from './users/filtering/redux/slices'
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import * as config from './reduxConfig.js';

const store = configureStore ({
  reducer: {
    [ipApi.reducerPath]: ipApi.reducer,
    filterTags: tagsReducer,
    assets: assetsReducer,
    users: usersReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ipApi.middleware).concat(logger),
});

if(config.allowReloadOnFocus) {
  setupListeners(store.dispatch);
}

const ReduxProvider = ({ children }) => (
  <Provider store={store}>{ children }</Provider>
);

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReduxProvider