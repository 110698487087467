
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const CardTitle = ({ children }) => {
  return (
    <Typography component="p" variant="h5">
      {children}
    </Typography>
  );
}

CardTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardTitle;