import * as React from 'react';
import PropTypes from 'prop-types';
import DataQueryTable from '../../../infrastructure/data/DataQueryTable';
import { columnFormat } from '../../../infrastructure/data/column';
import { toLocaleNumericFormat } from '../../../infrastructure/helpers';

const BidHistory = ({ queryResponse, data }) => (
  <DataQueryTable
    id="bid-history"
    queryResponse={queryResponse}
    data={data}
    columns={([{
        name: 'Status',
        rowLookup: row => row.status
      },
      {
        name: 'Date',
        format: columnFormat.DATE,
        rowLookup: row => row.date
      },
      {
        name: 'Amount',
        rowLookup: row => toLocaleNumericFormat(row.amount)
      },
      {
        name: 'Selection Type',
        rowLookup: row => row.jurisdictionsSelectionType
      },
      {
        name: 'Jurisdictions',
        rowLookup: row => row.applicableJurisdictions
      },
      {
        name: 'Note',
        rowLookup: row => row.note
      }
    ])}
  />
);


BidHistory.propTypes = {
  queryResponse: PropTypes.shape().isRequired,
  data: PropTypes.array.isRequired
};

export default BidHistory;