
import * as React from 'react';
import PropTypes from 'prop-types';
import DataQueryCard from '../infrastructure/data/DataQueryCard';

const UserItemDataCard = ({ queryResponse, data, children }) =>  (
  <DataQueryCard
    queryResponse={queryResponse}
    data={data}
    fields={[{
      isTitle: true,
      dataLookup: data => data.name
    }, {
      dataLookup: data => data.jobTitle
    }]}
  >
    {children}
  </DataQueryCard>
);

UserItemDataCard.propTypes = {
  queryResponse: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};


export default UserItemDataCard;