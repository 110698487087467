
import * as React from 'react';
import PropTypes from 'prop-types';
import CheckboxFormField from '../../../infrastructure/forms/CheckboxFormField';
import { getSaleTypeLabel } from '../../maintenance/sale-types/SaleTypes';
import JurisdictionSelectionTypes, { getJurisdictionSelectionTypeLabel } from '../../../jurisdictions/JurisdictionSelectionTypes';
import  { getUseAllJurisdcitionsFieldName, getUseSelectedJurisdcitionsFieldName } from './fields';

const JurisdictionTypeOptions = ({ saleType, disabled }) => (
  <div>
    <p>{`${getSaleTypeLabel(saleType)}:`}</p>
    <div>
      <CheckboxFormField
        id={getUseAllJurisdcitionsFieldName(saleType)}
        name={getUseAllJurisdcitionsFieldName(saleType)}
        label={getJurisdictionSelectionTypeLabel(JurisdictionSelectionTypes.All)}
        disabled={disabled}
      />
      <CheckboxFormField
        id={getUseSelectedJurisdcitionsFieldName(saleType)}
        name={getUseSelectedJurisdcitionsFieldName(saleType)}
        label={getJurisdictionSelectionTypeLabel(JurisdictionSelectionTypes.Selected)}
        disabled={disabled}
      />
    </div>
  </div>
);

JurisdictionTypeOptions.propTypes = {
  saleType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default JurisdictionTypeOptions;