import * as React from 'react';
import PropTypes from 'prop-types';
import { useController } from "react-hook-form";
import { commaSeparatedToList } from '../../../../infrastructure/helpers';
import { useFormValues } from '../../../../infrastructure/forms/hooks';
import SelectableJurisdictionsCloud from '../../../../jurisdictions/SelectableJurisdictionsCloud';
import JurisdictionsPopup from '../../../../jurisdictions/JurisdictionsPopup';
import SelectedJurisdictionsCloud from '../../../../jurisdictions/SelectedJurisdictionsCloud';
import JurisdictionSelectionTypes from '../../../../jurisdictions/JurisdictionSelectionTypes';
import SelectableJurisdictionsVisibility from './SelectableJurisdictionsVisibility';
import { useGetAllowedApplicableJurisdictions } from '../redux';
import * as fields from '../fields';

const SelectJurisdictionsInput = ({ packageId, selectedBid }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const { field } = useController({
        name: fields.applicableJurisdictionsFieldName
    });

    const formValues = useFormValues();
    const jurisdictionsSelectionType = formValues[fields.jurisdictionsSelectionTypeFieldName];
    const areAllJurisdictionSelected = jurisdictionsSelectionType === JurisdictionSelectionTypes.All;    
    const allowedJurisdictions = useGetAllowedApplicableJurisdictions(packageId, formValues[fields.bidTypeFieldName]);

    return (
        <SelectableJurisdictionsVisibility
          jurisdictionsSelectionType={jurisdictionsSelectionType}
          allowedJurisdictions={allowedJurisdictions}>
          <JurisdictionsPopup
            field={field}
            isOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <SelectableJurisdictionsCloud
              saleType={selectedBid.bidType}
              field={field}
              jurisdictions={allowedJurisdictions}
            />
          </JurisdictionsPopup>
          <SelectedJurisdictionsCloud
            saleType={selectedBid.bidType}
            field={field}
            onSelectJurisdictions={() => setDialogOpen(true)}
            disabled={areAllJurisdictionSelected}
            areAllJurisdictionsSelected={!areAllJurisdictionSelected && allowedJurisdictions.length === commaSeparatedToList(field.value).length}
            allowMoreSelection={!areAllJurisdictionSelected && allowedJurisdictions.length > commaSeparatedToList(field.value).length}
          />
        </SelectableJurisdictionsVisibility>
      );
}

SelectJurisdictionsInput.propTypes = {
  packageId: PropTypes.string.isRequired,
  selectedBid: PropTypes.shape().isRequired,
};

export default SelectJurisdictionsInput;