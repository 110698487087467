
import * as React from 'react';
import InformationAlert from '../../../infrastructure/InformationAlert';
  
const IpPackageFileUploadNotPossibleAlert = () => 
  <InformationAlert
    id="ip-package-file-upload-not-possible-alert"
    text="Please save changes first in order to be able to associate files"
  />

export default IpPackageFileUploadNotPossibleAlert;