import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../Visible';

const TabPanel = ({ currentIndex, index, children }) => {
  return (<Visible isVisible={currentIndex === index}>{children}</Visible>);
}

TabPanel.propTypes = {
  currentIndex: PropTypes.number.isRequired,  
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default TabPanel;