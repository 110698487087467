import * as React from 'react';
import { useGetRegisteredInterests } from './redux';
import UserFilter from '../../../users/filtering/UserFilter';

const RegisteredInterestsListFilter = () => {
  const interests = useGetRegisteredInterests();
  return (
    <UserFilter data={interests} />
  );
}

export default RegisteredInterestsListFilter;