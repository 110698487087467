import * as React from 'react';
import PropTypes from 'prop-types';
import Section from '../../infrastructure/layout/sections/Section';
import { useGetUserLogsQuery } from '../../api';
import { useGetUserLogsById } from '../redux';
import UserLogsList from './UserLogsList';

const UserLogsSection = ({ id }) =>  {
  const queryResponse = useGetUserLogsQuery(id);
  const data = useGetUserLogsById(id);
  return (
    <Section>
      <UserLogsList queryResponse={queryResponse} data={data} />
    </Section>
  );
}

UserLogsSection.propTypes = {
  id: PropTypes.string.isRequired
};


export default UserLogsSection;