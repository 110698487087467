
import * as React from 'react';
import PropTypes from 'prop-types';
import Section from '../infrastructure/layout/sections/Section';
import IpPackageItemTabs from './IpPackageItemTabs';
import IpPackageItemDataCard from './IpPackageItemDataCard';
import IpPackageItemGeneralTab from './IpPackageItemGeneralTab';
import IpPackageItemPictureTab from './pictures/IpPackageItemPictureTab';
import IpPackageItemRegisteredInterestTab from './interest/IpPackageItemRegisteredInterestTab';
import IpPackageItemBidsTab from './bids/IpPackageItemBidsTab';
import { useGetViewablePackageByIdQuery } from '../api';
import { useGetPackageById } from './redux/selectors';

const IpPackageItemSection = ({ packageId }) =>  {
  const queryResponse = useGetViewablePackageByIdQuery(packageId);
  const data = useGetPackageById(packageId);
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  
  return (
    <Section>
      <IpPackageItemDataCard queryResponse={queryResponse} data={data}>
         
        <IpPackageItemTabs
          packageId={packageId}
          currentTabIndex={currentTabIndex}
          onChangeTab={setCurrentTabIndex}
        />
        <IpPackageItemGeneralTab
          currentTabIndex={currentTabIndex}
          item={data}
        />
        <IpPackageItemPictureTab
          packageId={packageId}
          currentTabIndex={currentTabIndex}
          item={data}
        />
        <IpPackageItemRegisteredInterestTab
          currentTabIndex={currentTabIndex}
          packageId={packageId}
        />
        <IpPackageItemBidsTab
          currentTabIndex={currentTabIndex}
          packageId={packageId}
          getPackageQueryResponse={queryResponse}
        />

      </IpPackageItemDataCard>
    </Section>
  );
}

IpPackageItemSection.propTypes = {
  packageId: PropTypes.string.isRequired
};

export default IpPackageItemSection;