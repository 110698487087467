import { useMsal } from "@azure/msal-react";
import PropTypes from 'prop-types';
import Visible from '../../infrastructure/Visible';
import { getUserRole, isNewUser } from "./helpers";

const NewUserVisibility = ({ children }) => {  
  const msal = useMsal();
  const hasNewUser = isNewUser(msal);
  const hasRole = getUserRole(msal) !== undefined;
  return (
    <Visible isVisible={hasNewUser || !hasRole}>
        {children}
    </Visible>
    );
};

NewUserVisibility.propTypes = {
    children: PropTypes.node.isRequired
};

export default NewUserVisibility;