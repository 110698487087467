import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

export const  acquireAccessToken = async (loginRequest, msalConfig) => {
    const msalInstance = new PublicClientApplication(msalConfig);
    // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const activeAccount = msalInstance.getActiveAccount(); 
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        console.error("User is not logged in. Refresh the page to initiate login.");
    }
    const request = {
        scopes: loginRequest.scopes,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request)
        .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                return msalInstance.acquireTokenRedirect(request);
            }
        });
    return authResult.accessToken
};

export const formatBearerToken = (token) => `Bearer ${token}`;
export const AuthorizationHeaderName = 'authorization';