import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import DataQueryTable from '../infrastructure/data/DataQueryTable';
import { useGetFilteredUsers } from './redux';
import { columnFormat } from '../infrastructure/data/column';
import { usersPath } from '../AppRoutes';

const UsersList = ({ selectedId, response }) => {
  const users = useGetFilteredUsers();
  let navigate = useNavigate();

  return (
    <DataQueryTable
      id="users-list"
      queryResponse={response}
      data={users}
      onRowClick={(row) => navigate(`${usersPath}/${row.id}`)}
      selectedRowId={selectedId}
      columns={([{
          name: 'Name',
          rowLookup: row => row.name
        },
        {
          name: 'Email',
          rowLookup: row => row.email
        },
        {
          name: 'Organisation',
          rowLookup: row => row.organisation
        },
        {
          name: 'Job Title',
          rowLookup: row => row.jobTitle
        },
        {
          name: 'Activated',
          format: columnFormat.YESNO,
          rowLookup: row => row.activated
        },
      ])}
    />
  );
}

UsersList.propTypes = {
  response: PropTypes.shape().isRequired
};

export default UsersList;