import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { columnShape, format } from './column'
import TableContainer from '@mui/material/TableContainer';


const DataTable = ({ id, columns, rows, onRowClick, isSelectable, selectedRowId, page, rowsPerPage }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
          {columns.map((column, index) => (
            <TableCell variant="body" key={index}>{column.name}</TableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody id={`${id}-tbody`}>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow
              hover={isSelectable}
              key={row.id}
              onClick={() => { if (onRowClick) onRowClick(row) } }
              selected={isSelectable && selectedRowId && row.id === selectedRowId}
            >
                {columns.map((column, index) => (
                  <TableCell variant="body" key={index}>{format(column, row)}</TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DataTable.propTypes = {
  id: PropTypes.string,
  onRowClick: PropTypes.func,
  selectedRowId: PropTypes.string,
  isSelectable: PropTypes.bool,
  columns: PropTypes.arrayOf(columnShape).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

DataTable.defaultProps = {
  onRowClick: () => {},
  selectedRowId: undefined,
  isSelectable: false
}

export default DataTable;


