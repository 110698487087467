
import * as React from 'react';
import PropTypes from 'prop-types';

const CardField = ({ text }) => {
  return text.split('\n').map((str, idx) => <p key={idx}>{str}</p>);
}

CardField.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CardField;