import * as React from 'react';
import ActionButton from '../../../infrastructure/ActionButton';
import Edit from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

const RegisteredInterestEditButton = ({ onClick }) => {
    return (
        <ActionButton id="edit-nature-of-interest-button" onClick={onClick}>
            <Edit />&nbsp;Edit Nature of Interest
        </ActionButton>
    );
}

RegisteredInterestEditButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default RegisteredInterestEditButton;