
import * as React from 'react';
import AuthenticatedPage from '../../users/authentication/AuthenticatedPage';
import Sections from '../../infrastructure/layout/sections/Sections';
import MaintainIpPackagesSection from './MaintainIpPackagesSection';
import AppDrawerItems from '../../AppDrawerItems';

const MaintainIpPackageListPage = () => {
  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
          <MaintainIpPackagesSection />
      </Sections>
    </AuthenticatedPage>
  );
}

export default MaintainIpPackageListPage;