
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../../../infrastructure/layout/tabs/TabPanel';
import ItemCloud from '../../../../infrastructure/layout/ItemCloud';
import MaintainIpPackageLargeGridItem from '../../MaintainIpPackageLargeGridItem'
import PackagePictureDisplay from '../../../pictures/PackagePictureDisplay'

import IpPackageFileNameSection from '../IpPackageFileNameSection';
import { useUploadPictureFileMutation } from '../../../../api';
import { useGetPackagePictureFileName } from '../../redux/selectors';
import InformationAlert from '../../../../infrastructure/InformationAlert';

const MaintainIpPackageItemPicturesTab = ({ packageId, isNew, currentTabIndex }) =>  {
  const fileName = useGetPackagePictureFileName(packageId);
  const [uploadFile] = useUploadPictureFileMutation();

  return (
    <TabPanel
      currentIndex={currentTabIndex}
      index={4}
    >
      <MaintainIpPackageLargeGridItem>
      <InformationAlert text='It is recommended to keep the image size less than 250KB and image dimensions less than 1000 x 1000 pixels' />
      <PackagePictureDisplay
        packageId={packageId}
        fileName={fileName}
      />
      <ItemCloud>
        <IpPackageFileNameSection
          packageId={packageId}
          fileName={fileName}
          onUploadFile={uploadFile}
          isNew={isNew}
          acceptedFileTypes=".jpg,.png"
        />
      </ItemCloud>  
      </MaintainIpPackageLargeGridItem>
    </TabPanel>
  );
}

MaintainIpPackageItemPicturesTab.propTypes = {
  packageId: PropTypes.string.isRequired,  
  isNew: PropTypes.bool,  
  currentTabIndex: PropTypes.number.isRequired,
};

export default MaintainIpPackageItemPicturesTab;