
import * as React from 'react';
import PropTypes from 'prop-types';
import { useMsal } from "@azure/msal-react";
import TabsContainer from '../infrastructure/layout/tabs/TabsContainer';
import { Roles } from '../users/authentication/types';
import { hasUserGotAnyRolesFromList } from '../users/authentication/helpers';
import IpPackageStatuses from './IpPackageStatuses';
import { useGetHasPackageStatuses, useGetHasSaleTypesConfigured } from './redux/selectors'; 

const IpPackageItemTabs = ({ packageId, currentTabIndex, onChangeTab }) => {
  const msal = useMsal();
  const isOrganisationStandard = hasUserGotAnyRolesFromList(msal, [Roles.ORGANIZATION_STANDARD]);
  const packageIsPreview = useGetHasPackageStatuses(packageId, [IpPackageStatuses.Preview]);
  const packageIsActive = useGetHasPackageStatuses(packageId, [IpPackageStatuses.Active]);
  const packageHasSaleTypesConfigured = useGetHasSaleTypesConfigured(packageId);
  
  return (
    <TabsContainer
      currentTabIndex={currentTabIndex}
      onChangeTab={(newValue) => onChangeTab(newValue)}
      tabs={[{
        id: 'general-ip-package-item-tab',
        label: 'General'
      }, {
        id: 'picture-ip-package-item-tab',
        label: 'Image'
      }, {
        id: 'register-interest-ip-package-item-tab',
        label: 'Nature of interest',
        disabled: !isOrganisationStandard || !(packageIsPreview || packageIsActive)
      }, {
        id: 'bids-ip-package-item-tab',
        label: 'Bids',
        disabled: !isOrganisationStandard || !packageIsActive || !packageHasSaleTypesConfigured
      }]}
    />
  );
}

IpPackageItemTabs.propTypes = {
  packageId: PropTypes.string.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired
};


export default IpPackageItemTabs;