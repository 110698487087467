import * as React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from './LoadingIndicator';
import HttpErrorDisplay from './HttpErrorDisplay';
import Visible from '../Visible';

const DataQueryContainer = ({ id, queryResponse, children }) => (
  <React.Fragment>
    <div id={id}>
      <Visible isVisible={queryResponse && (queryResponse.isLoading || queryResponse.isFetching || queryResponse.isUninitialized)}>
        <LoadingIndicator />
      </Visible>
      <Visible isVisible={!queryResponse || queryResponse.isSuccess}>
        {children}
      </Visible>
      <Visible isVisible={queryResponse && queryResponse.isError}>
        <HttpErrorDisplay queryResponse={queryResponse} />
      </Visible>
    </div>
  </React.Fragment>
);

DataQueryContainer.propTypes = {
  id: PropTypes.string,
  queryResponse: PropTypes.shape(),
  children: PropTypes.node.isRequired
};

DataQueryContainer.defaultProps = {
  queryResponse: undefined
};

export default DataQueryContainer;