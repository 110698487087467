import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import IpPackageStatuses, { parseRawPackageStatus } from '../IpPackageStatuses';
import { ipApi } from '../../api'

const getResetPageCount = state => state.assets.resetPageCount;

const selectPackagesResult = ipApi.endpoints.getPackages.select()
const selectPackageByIdResult = (id) => ipApi.endpoints.getViewablePackageById.select(id);

export const getAllPackages = createSelector(
  selectPackagesResult,
  assetsResult => {
    const result = assetsResult?.data ?? { data: [] };
    return result.data;
  }
);

const emptyPackage = () => ({ 
  name: '',
  description: '',
  proprietor: '',
  tags: [],
  saleTypesConfiguration: [],
  bids: [],
  registeredInterests: [],
  status: IpPackageStatuses.Unavailable
});

export const getRawPackageById = id => createSelector(
  selectPackageByIdResult(id),
  packageResult => packageResult?.data ?? emptyPackage()
);

export const getPackageById = id => createSelector(
  getRawPackageById(id),
  ipPackage => ({ 
    ...ipPackage,
    status: parseRawPackageStatus(ipPackage.status)
  })
);

const getHasPackageStatuses = (id, statuses) => createSelector(
  getPackageById(id),
  ipPackage => statuses.includes(ipPackage.status) 
);

const getHasSaleTypesConfigured = id => createSelector(
  getPackageById(id),
  ipPackage => ipPackage.saleTypesConfiguration && ipPackage.saleTypesConfiguration.length > 0
);
  
export const useGetResetPageCount = () => useSelector(getResetPageCount);
export const useGetPackageById = id => useSelector(getPackageById(id));
export const useGetHasPackageStatuses = (id, statuses) => useSelector(getHasPackageStatuses(id, statuses));
export const useGetHasSaleTypesConfigured = id => useSelector(getHasSaleTypesConfigured(id));