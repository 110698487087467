import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { ipApi } from '../../../api'
import { parseJurisdictionSelectionType, getJurisdictionSelectionTypeLabel } from '../../../jurisdictions/JurisdictionSelectionTypes';
import { parseSaleType, getSaleTypeLabel } from '../../maintenance/sale-types/SaleTypes';
import { getFilteredData } from '../../../users/filtering/redux/selectors';
import { makeListCommaSeparated } from '../../../infrastructure/helpers';

const selectBidsResult = ipApi.endpoints.getBids.select();

const getRawBids = createSelector(
  selectBidsResult,
  bidsResult => {
    const result = bidsResult?.data ?? { data: [] };
    return result.data;
  }
);

const getBids = createSelector(
  getRawBids,
  bids => bids.map(bid => ({
    ...bid,
    bidType: getSaleTypeLabel(parseSaleType(bid.bidType)),
    amount: bid.amount.value,
    jurisdictionsSelectionType: getJurisdictionSelectionTypeLabel(parseJurisdictionSelectionType(bid.jurisdictionsSelectionType)),
    applicableJurisdictions: makeListCommaSeparated(bid.applicableJurisdictions)
  }))
);

const getFilteredBids = createSelector(
  getBids,
  getFilteredData,
  (bids, filteredBids) => filteredBids ?? bids
);

export const useGetBids = () => useSelector(getBids);
export const useGetFilteredBids = () => useSelector(getFilteredBids);