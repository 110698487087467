import * as React from 'react';
import PropTypes from 'prop-types';
import SimpleAutoCompleteFormField from '../../../infrastructure/forms/SimpleAutoCompleteFormField';
import { getInterestedFieldName } from '../fields';

export const isInterestedValueTrue = (value) => value === 'Yes';

const RegisterInterestInterestedInput = ({ saleType }) => (
  <SimpleAutoCompleteFormField
    id={getInterestedFieldName(saleType)}
    name={getInterestedFieldName(saleType)}       
    options={['Yes', 'No']}
    defaultValue={'Yes'}
    required
    label="Interested" 
  />
);

RegisterInterestInterestedInput.propTypes = {
  saleType: PropTypes.string.isRequired,
};

export default RegisterInterestInterestedInput;