
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const TabsContainer = ({
  currentTabIndex,
  onChangeTab,
  tabs,
}) => { 

  const renderTabs = (tabs) => {
    if(tabs.length === 0) {
      return null;
    }
    return tabs.map((tab) => {
      if(tab.disabled) {
        return null;
      } 
      return (<Tab id={tab.id} key={tab.id} label={tab.label} />);
    }) 
  }

  return (
    <Tabs
      value={currentTabIndex}
      onChange={(_event, newValue) => onChangeTab(newValue)}
    >
      {renderTabs(tabs)}
    </Tabs>
  );
}

TabsContainer.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default TabsContainer;