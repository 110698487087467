
import * as React from 'react'
import PropTypes from 'prop-types';
import DialogButton from './DialogButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteConfirmationDialog = ({ id, title, bodyText, isOpen, onClose, onDelete }) => {
    return (
    <Dialog
      id={id}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
          <DialogButton
            id={`cancel-${id}-button`}
            onClick={onClose}
            autoFocus
          >
            Do Not Delete
          </DialogButton>
          <DialogButton
            id={`confirm-${id}-button`}
            onClick={onDelete}
          >
            Delete
          </DialogButton>
        </DialogActions>
    </Dialog>
  );
}

DeleteConfirmationDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationDialog;

