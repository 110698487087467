
import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form'
import { useGetPackageById } from './redux/selectors';
import * as generalFields from './general/fields';
import * as saleTypeFields from './sale-types/fields';
import * as statusFields from './statuses/fields';

const IpPackageMaintenanceFormLoader = ({ packageId, children }) => {
  const { reset, setValue } = useFormContext();
  const ipPackage = useGetPackageById(packageId);
    
  React.useEffect(() => {
    reset({});
    generalFields.setGeneralFormFields(
      ipPackage.name,
      ipPackage.description,
      ipPackage.proprietor,
      ipPackage.tags,
      setValue
    );
    saleTypeFields.setSaleTypesConfigurationFormFields(
      ipPackage.saleTypesConfiguration.assignment,
      ipPackage.saleTypesConfiguration.exclusiveLicense,
      ipPackage.saleTypesConfiguration.nonExclusiveLicense, 
      setValue
    );
    statusFields.setStatusesFormFields( 
      ipPackage.statuses.draft,
      ipPackage.statuses.preview,
      ipPackage.statuses.active,
      ipPackage.statuses.closing,
      ipPackage.statuses.closed,
      setValue
    );
  }, [
    ipPackage.name,
    ipPackage.description,
    ipPackage.proprietor,
    ipPackage.tags,
    ipPackage.saleTypesConfiguration.assignment,
    ipPackage.saleTypesConfiguration.exclusiveLicense,
    ipPackage.saleTypesConfiguration.nonExclusiveLicense, 
    ipPackage.statuses.draft,
    ipPackage.statuses.preview,
    ipPackage.statuses.active,
    ipPackage.statuses.closing,
    ipPackage.statuses.closed,
    reset,
    setValue
  ]);

  return (
      <>
        {children}
      </>
  );
}

IpPackageMaintenanceFormLoader.propTypes = {
  packageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default IpPackageMaintenanceFormLoader;