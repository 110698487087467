import * as React from 'react';
import PropTypes from 'prop-types';
import { maxLengthRule } from '../../../infrastructure/forms/validation';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { getDetailsFieldName } from '../fields';

const RegisterInterestDetailsInput = ({ saleType }) => (
  <TextFormField 
    name={getDetailsFieldName(saleType)}
    fullWidth
    label="Add extra detail here"
    variant="outlined"
    validation={{
      maxLength: maxLengthRule(500)
    }}
  />
);

RegisterInterestDetailsInput.propTypes = {
  saleType: PropTypes.string.isRequired,
};

export default RegisterInterestDetailsInput;