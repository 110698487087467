
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'
import BoltIcon from '@mui/icons-material/Bolt';

const StatusChip = ({ id, label }) => (
    <Chip
        id={id}
        icon={<BoltIcon />}
        label={label}
    />
);

StatusChip.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default StatusChip;