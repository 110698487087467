import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { parseSaleType, getAllSaleTypeOptions } from '../../maintenance/sale-types/SaleTypes';
import { parseJurisdictionSelectionType, getAllJurisdictionsSelectionTypeOptions } from '../../../jurisdictions/JurisdictionSelectionTypes';
import { getPackageById } from '../../redux/selectors'

const getSaleTypeConfiguration = (packageId, saleType) => createSelector(
  getPackageById(packageId),
  ipPackage => ipPackage.saleTypesConfiguration.find(s => parseSaleType(s.saleType) === saleType)
);

const getCanBid = (packageId) => createSelector(
  getPackageById(packageId),
  ipPackage => ipPackage.saleTypesConfiguration.length > ipPackage.bids.length
);

const getAllowedSaleTypeOptions = (packageId, disabled) => createSelector(
  getPackageById(packageId),
  ipPackage => {
    const allSaleTypeOptions = getAllSaleTypeOptions();
    const allowedSaleTypeOptions = ipPackage.saleTypesConfiguration?.map(config => allSaleTypeOptions
      .find(option => option.value === parseSaleType(config.saleType)));
      
    if (disabled)
      return allowedSaleTypeOptions;

    const existingBidTypes = ipPackage.bids && ipPackage.bids.length > 0 ? ipPackage.bids.map(bid => parseSaleType(bid.bidType)) : [];
    return allowedSaleTypeOptions?.filter(option => !existingBidTypes.includes(option.value));
  }
);

const getAllowedJurisdictionsSelectionTypeOptions = (packageId, saleType) => createSelector(
  getSaleTypeConfiguration(packageId, saleType),
  saleTypeConfiguration => {
    const allJurisdictionsSelectionTypeOptions = getAllJurisdictionsSelectionTypeOptions();
    return saleTypeConfiguration?.allowedJurisdictionsSelectionTypes
      .map(type => allJurisdictionsSelectionTypeOptions.find(option => option.value === parseJurisdictionSelectionType(type)))
      ?? [];
  }
);

const getAllowedApplicableJurisdictions = (packageId, saleType) => createSelector(
  getSaleTypeConfiguration(packageId, saleType),
  saleTypeConfiguration => saleTypeConfiguration?.allowedApplicableJurisdictions ?? []
);

export const useGetCanAddBid = packageId => useSelector(getCanBid(packageId));
export const useGetAllowedSaleTypeOptions = (packageId, disabled) => useSelector(getAllowedSaleTypeOptions(packageId, disabled));
export const useGetAllowedJurisdictionsSelectionTypeOptions = (packageId, saleType) => useSelector(getAllowedJurisdictionsSelectionTypeOptions(packageId, saleType));
export const useGetAllowedApplicableJurisdictions = (packageId, saleType) => useSelector(getAllowedApplicableJurisdictions(packageId, saleType));
