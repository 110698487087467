
import * as React from 'react';
import PropTypes from 'prop-types';
import Send from '@mui/icons-material/Send';
import { usePrompt } from '../layout/Prompt';
import SubmitButton from '../data/SubmitButton';
import CancelButton from '../data/CancelButton';
import HorizontalLayout from '../layout/HorizontalLayout';
import Visible from '../Visible';

const SubmitOrCancelFormSection = ({ id, title, formContext, onCancel }) => {
  
  usePrompt("You have unsaved changes. Discard changes?", formContext.formState.isDirty);

  return (  
    <HorizontalLayout>
      <SubmitButton id={`${id}-submit`}><Send />&nbsp;{title}</SubmitButton>
      <Visible isVisible={!!onCancel}>
        <CancelButton id={`${id}-cancel`} onClick={onCancel} />
      </Visible>
    </HorizontalLayout>  
  );
}

SubmitOrCancelFormSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  formContext: PropTypes.shape().isRequired,
  onCancel: PropTypes.func
};

SubmitOrCancelFormSection.defaultProps = {
  onCancel: undefined
}

export default SubmitOrCancelFormSection;