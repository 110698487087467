import * as React from 'react';
import PropTypes from 'prop-types';
import SelectableJurisdictionsCloud from '../../../../jurisdictions/SelectableJurisdictionsCloud';
import { useGetJurisdictions } from '../redux/selectors'; 

const FullJurisdictionCloud = ({ saleType, field }) => { 
  const jurisdictions = useGetJurisdictions();
  return (
    <SelectableJurisdictionsCloud
      saleType={saleType}
      field={field}
      jurisdictions={jurisdictions}
    />
  );
}

FullJurisdictionCloud.propTypes = {
  saleType: PropTypes.string.isRequired,
  field: PropTypes.shape().isRequired
};

export default FullJurisdictionCloud;