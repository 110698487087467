
import * as React from 'react';
import PropTypes from 'prop-types';
import FormValidationTabs from '../../infrastructure/forms/validation/FormValidationTabs';
import * as generalFields from './general/fields';
import * as statusesFields from './statuses/fields';
import IpPackageStatuses from '../IpPackageStatuses';

const MaintainIpPackageItemTabs = ({ currentTabIndex, onChangeTab }) => {
  return (
    <FormValidationTabs
      currentTabIndex={currentTabIndex}
      onChangeTab={(newValue) => onChangeTab(newValue)}
      tabs={[{
        id: 'general-maintain-ip-package-item-tab',
        label: 'General',
        validationFields: [
          generalFields.ipPackageNameFieldName,
          generalFields.ipPackageDescriptionFieldName,
          generalFields.ipPackageProprietorFieldName,
          generalFields.ipPackageTagsFieldName
        ]
      }, {
        id: 'sale-types-maintain-ip-package-item-tab',
        label: 'Sale types',
        validationFields: []
      }, {
        id: 'statuses-maintain-ip-package-item-tab',
        label: 'Statuses',
        validationFields: [
          statusesFields.getActiveFromFieldName(IpPackageStatuses.Draft),
          statusesFields.getActiveFromFieldName(IpPackageStatuses.Preview),
          statusesFields.getActiveFromFieldName(IpPackageStatuses.Active),
          statusesFields.getActiveFromFieldName(IpPackageStatuses.Closing),
          statusesFields.getActiveFromFieldName(IpPackageStatuses.Closed)
        ]
      }, {
        id: 'pack-maintain-ip-package-item-tab',
        label: 'Pack',
        validationFields: []
      }, {
        id: 'picture-maintain-ip-package-item-tab',
        label: 'Image',
        validationFields: []
      }]}
    />
  );
}

MaintainIpPackageItemTabs.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired
};


export default MaintainIpPackageItemTabs;