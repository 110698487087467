
import * as React from 'react';
import { useParams } from 'react-router';
import Sections from '../infrastructure/layout/sections/Sections';
import AppDrawerItems from '../AppDrawerItems';
import AuthenticatedPage from './authentication/AuthenticatedPage';
import UsersSection from './UsersSection';
import UserItemSection from './UserItemSection';
import UserLogsSection from './logs/UserLogSection';
          
const UserItemPage = () => {
  let { id } = useParams();
  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
        <UsersSection selectedId={id} />
        <UserItemSection id={id}/>
        <UserLogsSection id={id}/>
      </Sections>
    </AuthenticatedPage>
  );
}

export default UserItemPage;