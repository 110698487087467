import * as React from 'react';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { ipPackageTagsFieldName } from './fields';

const IpPackageTagsInput = () => {  
  return (
    <TextFormField  
      id="ip-package-tags-field"
      name={ipPackageTagsFieldName}
      label="Tags"
      variant="outlined"
      required
      fullWidth
    />
  );
}

export default IpPackageTagsInput;