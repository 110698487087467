
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import FileIcon from '@mui/icons-material/AttachFile'

const FileUploadChip = ({ id, fileName, fileInputRef }) => {    
    return (
        <Chip
            id={`${id}-chip`}
            icon={<FileIcon />}
            label={fileName ? fileName : 'Choose file'}
            color={"primary"}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
        />
    );
}
    
FileUploadChip.propTypes = {
    id: PropTypes.string,
    fileName: PropTypes.string,
    fileInputRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })
    ])
};

export default FileUploadChip;
