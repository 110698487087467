import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

const DrawerContent = ({ setIsOpen, children }) => {
  const setOpenforKey = (toSet) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(toSet);
  };
  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setIsOpen(false)}
      onKeyDown={() => setOpenforKey(false)}
    >
      <List>            
        {children}
      </List>
    </Box>
  );
};

DrawerContent.propTypes = {
  children: PropTypes.node.isRequired,
    setIsOpen: PropTypes.func.isRequired
};

export default DrawerContent;