
import * as React from 'react';
import PropTypes from 'prop-types';
import SelectableChip from '../../infrastructure/chips/SelectableChip'
import TagIcon from '@mui/icons-material/LocalOffer'

const TagChip = ({ id, label, selected, onClick }) =>(
    <SelectableChip
        id={id}
        icon={<TagIcon />}
        label={label}
        selected={selected}
        onClick={onClick}
    />
);

TagChip.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func
};

TagChip.defaultProps = {
    selected: false,
    onClick: null
}
export default TagChip;