import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'users',
  initialState: { filteredData: null },
  reducers: {
    setFilteredData: (
      state, { payload: { filteredData } }
    ) => {
      state.filteredData = filteredData;
    }
  }
});

export const { setFilteredData } = slice.actions
export default slice.reducer