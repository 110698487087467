import * as React from 'react';
import PropTypes from 'prop-types';
import ItemCloud from '../infrastructure/layout/ItemCloud';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';
import JurisdictionItem from './JurisdictionItem'; 

const renderItems = (saleType, field, jurisdictions) => jurisdictions.map(
  jurisdiction => (
    <ItemCloudItem key={jurisdiction}>
      <JurisdictionItem
        saleType={saleType}
        field={field}
        jurisdiction={jurisdiction}
      />
    </ItemCloudItem>
  )
);

const SelectableJurisdictionsCloud = ({ saleType, field, jurisdictions }) => (
  <ItemCloud>{renderItems(saleType, field, jurisdictions)}</ItemCloud>
);

SelectableJurisdictionsCloud.propTypes = {
  saleType: PropTypes.string,
  field: PropTypes.shape().isRequired,
  jurisdictions: PropTypes.arrayOf(PropTypes.string).isRequired
};

SelectableJurisdictionsCloud.defaultProps = {
  saleType: 'unknown'
}

export default SelectableJurisdictionsCloud;