
import * as React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

const ItemInformation = ({ text, children }) => (
    <Tooltip title={text} arrow>
      <span>
        {children}
      </span>
    </Tooltip>
);
  
ItemInformation.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ItemInformation;