
import * as React from 'react';
import PropTypes from 'prop-types';
import FileUploadInput from './FileUploadInput';
import FileUploadChip from './FileUploadChip';

const FileUploadTag = ({ id, fileName, accept, onUpload }) => {
    const fileInputRef = React.useRef(null);
    
    return (
        <React.Fragment>
            <FileUploadInput
                id={id}
                accept={accept}
                onChange={file => {
                    onUpload(file);
                }}
                forwardedRef={fileInputRef}
            />
            <FileUploadChip
                id={id}
                fileName={fileName}
                fileInputRef={fileInputRef}
            />
        </React.Fragment>
    );
}
    
FileUploadTag.propTypes = {
    id: PropTypes.string,
    fileName: PropTypes.string,
    accept: PropTypes.string.isRequired,
    onUpload: PropTypes.func.isRequired,
};

export default FileUploadTag;
