
import * as React from 'react';
import { Route, Routes } from 'react-router-dom'
import IpPackageListPage from './intellectual-property/IpPackageListPage';
import IpPackageItemPage from './intellectual-property/IpPackageItemPage';
import MaintainIpPackageListPage from './intellectual-property/maintenance/MaintainIpPackageListPage';
import MaintainIpPackagePage from './intellectual-property/maintenance/MaintainIpPackagePage';
import NewIpPackagePage from './intellectual-property/maintenance/NewIpPackagePage';
import RegisteredInterestsListPage from './intellectual-property/interest/list/RegisteredInterestsListPage';
import BidsListPage from './intellectual-property/bids/list/BidsListPage';
import BidHistoryPage from './intellectual-property/bids/history/BidHistoryPage';
import UsersListPage from './users/UsersListPage';
import UserItemPage from './users/UserItemPage';

const idPath = ":id"
const getItemPath = (path) => `${path}/${idPath}`
const getNewItemPath = (path) => `${path}/new`
export const bindIdToPath = (path, id) => path.replace(idPath, id);
export const registeredinterestsPath = "/registered-interests";
export const maintainPackagesPath = "/packages/maintain";
export const maintainPackagePath = getItemPath(maintainPackagesPath);
export const newPackagePath = getNewItemPath(maintainPackagesPath);
export const bidsPath = "/bids";
export const bidHistoryPath = "/bid-history";
export const bidHistoryItemPath = getItemPath(bidHistoryPath);
export const usersPath = "/users";
export const usersIdPath = getItemPath(usersPath);
export const loginPath = "/login";
export const packageIdPath = getItemPath("");
  
const AppRoutes = () => {
  return (
    <Routes>
        <Route exact path="/" element={<IpPackageListPage />} />
        <Route path={packageIdPath} element={<IpPackageItemPage />} />
        <Route path={maintainPackagesPath} element={<MaintainIpPackageListPage />} />
        <Route path={maintainPackagePath} element={<MaintainIpPackagePage />} />
        <Route path={newPackagePath} element={<NewIpPackagePage />} />
        <Route exact path={registeredinterestsPath} element={<RegisteredInterestsListPage />} />
        <Route exact path={bidsPath} element={<BidsListPage />} />
        <Route exact path={bidHistoryItemPath} element={<BidHistoryPage />} />
        <Route exact path={usersPath} element={<UsersListPage />} />
        <Route exact path={usersIdPath} element={<UserItemPage />} />
      </Routes>
  );
}

export default AppRoutes;