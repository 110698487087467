
import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack'

const HorizontalLayout = ({ children }) => (
  <Stack direction="row" spacing={2}>{children}</Stack>
);
  
HorizontalLayout.propTypes = {
  children: PropTypes.node
};

export default HorizontalLayout;