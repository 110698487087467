
import * as React from 'react';
import PropTypes from 'prop-types';
import TagChip from './TagChip'
import { useGetAreAnyTagsFromAssetsSelected } from './redux/selectors';

const SelectTagsButton = ({ onClick }) =>  {
  let areAnyTagsSelected = useGetAreAnyTagsFromAssetsSelected();
  return (
    <TagChip
      id="select-tags-button"
      label={areAnyTagsSelected ? 'Select more...' : 'Select tags'}
      selected={true}
      onClick={onClick}
    />
  );
}

SelectTagsButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SelectTagsButton;

