import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Visible from '../../../Visible';
import SmallLogo from '../../../images/SmallLogo';
import MinimumWidthVisibilty from '../../MinimumWidthVisibilty';
import MaximumWidthVisibilty from '../../MaximumWidthVisibilty';
import PageTitle from '../PageTitle';
import AppBar from './AppBar';
import PageToolBarLeftContent from './PageToolBarLeftContent';
import MenuButton from './MenuButton';
import TextLogo from '../../../text/TextLogo';


const ToolBar = ({ onMenuButtonClick, showMenu }) => {
  return (
    <AppBar>
      <Toolbar>        
        <Visible isVisible={showMenu}>
          <MenuButton onClick={onMenuButtonClick} />
        </Visible>
        <PageToolBarLeftContent>
          <MinimumWidthVisibilty minimumWidth="601px">
            <SmallLogo />
          </MinimumWidthVisibilty>
          <MaximumWidthVisibilty maximumWidth="600px">
            <TextLogo />
          </MaximumWidthVisibilty>
        </PageToolBarLeftContent>
        <PageTitle />
      </Toolbar>
    </AppBar>
  );
}

ToolBar.propTypes = {
  onMenuButtonClick: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired
};

export default ToolBar;