
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../../infrastructure/layout/tabs/TabPanel';
import IpPackageNameInput from './IpPackageNameInput';
import IpPackageDescriptionInput from './IpPackageDescriptionInput';
import IpPackageProprietorInput from './IpPackageProprietorInput';
import IpPackageTagsInput from './IpPackageTagsInput';
import MaintainIpPackageLargeGridItem from '../MaintainIpPackageLargeGridItem'

const MaintainIpPackageItemGeneralTab = ({ currentTabIndex }) =>  {
  return (
    <TabPanel
      currentIndex={currentTabIndex}
      index={0}
    >
      <MaintainIpPackageLargeGridItem>
        <IpPackageNameInput />
      </MaintainIpPackageLargeGridItem>
      <MaintainIpPackageLargeGridItem>
        <IpPackageDescriptionInput />
      </MaintainIpPackageLargeGridItem>
      <MaintainIpPackageLargeGridItem>
        <IpPackageProprietorInput />
      </MaintainIpPackageLargeGridItem>
      <MaintainIpPackageLargeGridItem>
        <IpPackageTagsInput />
      </MaintainIpPackageLargeGridItem>
    </TabPanel>
  );
}

MaintainIpPackageItemGeneralTab.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
};

export default MaintainIpPackageItemGeneralTab;