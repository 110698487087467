import * as React from 'react';
import PropTypes from 'prop-types';
import DataQueryTable from '../../../infrastructure/data/DataQueryTable';
import { columnFormat } from '../../../infrastructure/data/column';
import { useGetFilteredRegisteredInterests } from './redux';

const RegisteredInterestsList = ({ response }) => {
  const interests = useGetFilteredRegisteredInterests();

  return (
    <DataQueryTable
      queryResponse={response}
      data={interests}
      columns={([{
          name: 'IP Package Name',
          rowLookup: row => row.packageName
        },
        {
          name: 'Date',            
          format: columnFormat.DATE,
          rowLookup: row => row.date
        },
        {
          name: 'Name', 
          rowLookup: row => row.name
        },
        {
          name: 'Email',
          rowLookup: row => row.email
        },
        {
          name: 'Job Title',
          rowLookup: row => row.jobTitle
        },
        {
          name: 'Organisation',
          rowLookup: row => row.organisation
        },
        {
          name: 'Assignment',
          rowLookup: row => row.assignment
        },
        {
          name: 'Assignment Details',
          rowLookup: row => row.assignmentDetails
        },
        {
          name: 'Exclusive License',
          rowLookup: row => row.exclusiveLicense
        },
        {
          name: 'Exclusive License Details',
          rowLookup: row => row.exclusiveLicenseDetails
        },
        {
          name: 'Non Exclusive License',
          rowLookup: row => row.nonExclusiveLicense
        },
        {
          name: 'Non Exclusive License Details',
          rowLookup: row => row.nonExclusiveLicenseDetails
        },
      ])}
    />
  );
}

RegisteredInterestsList.propTypes = {
  response: PropTypes.shape().isRequired
};

export default RegisteredInterestsList;