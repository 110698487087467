import * as React from 'react';
import PropTypes from 'prop-types';
import { useController } from "react-hook-form";
import DataQueryContainer from '../../../../infrastructure/data/DataQueryContainer';
import JurisdictionsPopup from '../../../../jurisdictions/JurisdictionsPopup';
import SelectedJurisdictionsCloud from '../../../../jurisdictions/SelectedJurisdictionsCloud';
import { useGetJurisdictionsQuery } from '../../../../api';
import FullJurisdictionCloud from './FullJurisdictionCloud';
import * as fields from './fields';

const SelectJurisdictionsInput = ({ saleType, disabled }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  
  const { field } = useController({ 
    name: fields.getSaleTypeApplicableJurisdictionsFieldName(saleType) 
  });
  
  const queryResponse = useGetJurisdictionsQuery();
   
  return (
    <DataQueryContainer queryResponse={queryResponse}>
      <JurisdictionsPopup
        field={field}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <FullJurisdictionCloud
          saleType={saleType}
          field={field}
        />
      </JurisdictionsPopup>
      <SelectedJurisdictionsCloud
        saleType={saleType}
        field={field}
        onSelectJurisdictions={() => setDialogOpen(true)}
        disabled={disabled}
      />
    </DataQueryContainer>
  );
}


SelectJurisdictionsInput.propTypes = {
  saleType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SelectJurisdictionsInput;