import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import TagChip from './TagChip';
import { selectTag } from './redux/slices';
import { useIsTagSelected } from './redux/selectors';

const TagFilterItem = ({ tag }) => {
  const dispatch = useDispatch();
  let isSelected = useIsTagSelected(tag);
  return (
    <TagChip
      id={tag}
      key={tag}
      label={tag}
      selected={isSelected}
      onClick={() => dispatch(selectTag({ tag }))}
    />
  );
}

TagFilterItem.propTypes = {
  tag: PropTypes.string.isRequired
};

export default TagFilterItem;