
import * as React from 'react';
import PropTypes from 'prop-types';
import EmailChip from '../infrastructure/chips/EmailChip';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';

const UserEmail = ({ user }) => (
  <ItemCloudItem key={user.proprietor}><EmailChip label={user.email ?? 'None'} /></ItemCloudItem>
);
  
UserEmail.propTypes = {
  user: PropTypes.shape().isRequired
};

export default UserEmail;