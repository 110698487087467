import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form'
import * as fields from './fields';
import { useGetPackageBid } from '../redux';
import { makeListCommaSeparated } from '../../../infrastructure/helpers';

const BidFormLoader = ({ packageId, selectedBid, children }) => {
  const { reset, setValue } = useFormContext();
  const bid = useGetPackageBid(packageId, selectedBid.bidType); 
  
  React.useEffect(() => {
    reset({});
    setValue(fields.bidTypeFieldName, bid.bidType);    
    setValue(fields.bidAmountFieldName, bid.amount);
    setValue(fields.jurisdictionsSelectionTypeFieldName, bid.jurisdictionsSelectionType);
    setValue(fields.applicableJurisdictionsFieldName, makeListCommaSeparated(bid.applicableJurisdictions));
    setValue(fields.noteFieldName, bid.note);
  }, [
    bid.bidType,
    bid.amount,
    bid.jurisdictionsSelectionType,
    bid.applicableJurisdictions,
    bid.note,
    reset,
    setValue
  ]);

  return (
      <>
        {children}
      </>
  );
}

BidFormLoader.propTypes = {
  packageId: PropTypes.string.isRequired,
  selectedBid: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired
};

export default BidFormLoader;