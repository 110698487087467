
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'

const ActionButton = ({ id, children, onClick, ...rest }) =>(
    <Button 
        id={id}
        variant="contained"
        onClick={onClick}
        {...rest}
    >
        {children} 
    </Button>
);

ActionButton.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ActionButton;
