
import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import RegisterInterestInterestedInput from './RegisterInterestInterestedInput';
import RegisterInterestDetailsInput from './RegisterInterestDetailsInput';
import { getSaleTypeLabel } from '../../maintenance/sale-types/SaleTypes';

const RegisterInterestSmallGridItem = ({ children }) => <Grid item xs={2}>{children}</Grid>;
const RegisterInterestMediumGridItem = ({ children }) => <Grid item xs={8}>{children}</Grid>;
const RegisterInterestTitle = ({ saleType }) => <p>{getSaleTypeLabel(saleType)}</p>;

const RegisterInterestItem = ({ saleType }) => (
  <React.Fragment>
    <RegisterInterestSmallGridItem>
      <RegisterInterestTitle saleType={saleType} />
    </RegisterInterestSmallGridItem>
    <RegisterInterestSmallGridItem>
      <RegisterInterestInterestedInput saleType={saleType}/>
    </RegisterInterestSmallGridItem>
    <RegisterInterestMediumGridItem>
      <RegisterInterestDetailsInput saleType={saleType} />
    </RegisterInterestMediumGridItem>
  </React.Fragment>
);

RegisterInterestItem.propTypes = {
  saleType: PropTypes.string.isRequired
};

export default RegisterInterestItem;