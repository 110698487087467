
import * as React from 'react';
import PropTypes from 'prop-types';
import TagChip from './filtering/TagChip';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';

const IpPackageItemTags = ({ item }) => item.tags.map(
  (tag, index) => (
    <ItemCloudItem key={index}><TagChip label={tag} /></ItemCloudItem>
  )
);

IpPackageItemTags.propTypes = {
  item: PropTypes.shape()
};

export default IpPackageItemTags;