
import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../../infrastructure/Visible';
import { useGetHasNoInterest } from '../redux';

const NoInterestVisibility = ({ packageId, children }) => {
  const hasNoInterest = useGetHasNoInterest(packageId);
  return (
    <Visible isVisible={hasNoInterest}>
      {children}
    </Visible>
  );
}

NoInterestVisibility.propTypes = {
  packageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NoInterestVisibility;

