import * as React from 'react';
import PropTypes from 'prop-types';
import { fieldShape } from './field'
import VerticalLayout from '../layout/VerticalLayout';
import DataCardField from './DataCardField';

const renderFields = (data, fields) => fields.map(
  (field, index) => (
    <DataCardField key={index} data={data} field={field} />
  )
);

const DataCard = ({ data, fields, children }) => (
  <VerticalLayout>
    {renderFields(data, fields)}
    {children}
  </VerticalLayout>
);

DataCard.propTypes = {
  data: PropTypes.shape().isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(fieldShape)),
  children: PropTypes.node.isRequired  
};

DataCard.defaultProps = {
  fields: []  
};

export default DataCard;