import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const PageBackgroundBox = ({ children }) => (
  <Box sx={{
    backgroundColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  }}>
    { children }
  </Box>
);

PageBackgroundBox.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageBackgroundBox;