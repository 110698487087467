
import { commaSeparatedToList, removeWhitespace } from '../../../infrastructure/helpers';

export const ipPackageNameFieldName = 'IpPackageName';
export const ipPackageDescriptionFieldName = 'IpPackageDescription';
export const ipPackageProprietorFieldName = 'IpPackageProprietor';
export const ipPackageTagsFieldName = 'IpPackageTags';
export const getTagsFromForm = (formValues) => commaSeparatedToList(removeWhitespace(formValues[ipPackageTagsFieldName]));

export const setGeneralFormFields = (name, description, proprietor, tags, setValue) => {
    setValue(ipPackageNameFieldName, name);
    setValue(ipPackageDescriptionFieldName, description);
    setValue(ipPackageProprietorFieldName, proprietor);
    setValue(ipPackageTagsFieldName, tags);
}

