import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterList from '@mui/icons-material/FilterList';

const FilterTextField = ({label, onChange, text}) => (
    <TextField 
        value={text}
        onChange={onChange}
        placeholder={label}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <FilterList  />
                </InputAdornment>
            ),
        }}
        variant="standard"

    />
);

FilterTextField.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export default FilterTextField;