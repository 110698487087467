
import * as React from 'react';
import PropTypes from 'prop-types';
import FileUploadTag from '../../../infrastructure/files/FileUploadTag';
  
const IpPackageFileNameTag = ({ packageId, fileName, onUploadFile, onFileUploaded, acceptedFileTypes }) =>  {
  
  const getFileForm = (packageId, file) => {
    const form = new FormData();
    form.append('packageId', packageId);
    form.append('name', file.name);
    form.append('file', file.actualFile);
    return form;
  };

  return (
      <FileUploadTag
        id='ip-package-file'
        fileName={fileName}
        accept={acceptedFileTypes}
        onUpload={async (file) => {
          await onUploadFile(getFileForm(packageId, file)).unwrap();
          if(onFileUploaded) {
            onFileUploaded();
          }
        }}
      />
  );
}

IpPackageFileNameTag.propTypes = {
  packageId: PropTypes.string.isRequired,  
  fileName: PropTypes.string,
  acceptedFileTypes: PropTypes.string.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func,
};

  
export default IpPackageFileNameTag;