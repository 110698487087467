import * as React from 'react';
import Section from '../../../infrastructure/layout/sections/Section';
import SectionTitle from '../../../infrastructure/text/SectionTitle';
import BidsListFilter from './BidsListFilter'
import BidsList from './BidsList'
import { useGetBidsQuery } from '../../../api';

const BidsListSection = () => {
  const response = useGetBidsQuery();

  return (
    <Section>
      <SectionTitle>IP Package Bids List</SectionTitle>
      <BidsListFilter />
      <BidsList response={response}></BidsList>
    </Section>
  );
}

export default BidsListSection;