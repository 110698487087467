
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'
import EmailIcon from '@mui/icons-material/Email';

const EmailChip = ({ label }) => <Chip icon={<EmailIcon />} label={label} />;

EmailChip.propTypes = {
    label: PropTypes.string.isRequired
};

export default EmailChip;