import { Fragment } from 'react';
import Page from '../../infrastructure/layout/pages/Page';
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import PropTypes from 'prop-types';
import NotNewUserVisibility from './NotNewUserVisibility';
import NewUserVisibility from './NewUserVisibility';
import NewUserPageContent from './NewUserPageContent';
import AuthenticationErrorPage from './AuthenticationErrorPage';

const AuthenticatedPage = ({ children, drawerItems }) => {
    const authRequest = {
        ...loginRequest
    };

    return (<Fragment>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={AuthenticationErrorPage}
        >
            <Page drawerItems={drawerItems}>
                <NotNewUserVisibility>
                    {children}
                </NotNewUserVisibility>
                <NewUserVisibility>
                    <NewUserPageContent />
                </NewUserVisibility>
            </Page>
        </MsalAuthenticationTemplate>
    </Fragment>);
};

AuthenticatedPage.propTypes = {
    children: PropTypes.node.isRequired,
    drawerItems: PropTypes.node.isRequired
};
  
export default AuthenticatedPage;
