
import * as React from 'react'
import PropTypes from 'prop-types';
import DeleteButton from '../../infrastructure/data/DeleteButton'
import DeleteIpPackageDialog from './DeleteIpPackageDialog'
import { useNavigate } from 'react-router-dom'
import { maintainPackagesPath } from '../../AppRoutes';
import { useDeletePackageMutation } from '../../api';


const DeleteIpPackageButton = ({ packageId }) => {
  const navigate = useNavigate();
  const [deletePackage] = useDeletePackageMutation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  
  const onDelete = () => {
    deletePackage({ id: packageId })
    setDialogOpen(false);
    navigate(maintainPackagesPath, { replace: true });
  }
    
  const onClose = () => {
    setDialogOpen(false);
  }

  return (
    <React.Fragment>
      <DeleteIpPackageDialog
        isOpen={dialogOpen}
        onDelete={onDelete}
        onClose={onClose}
      />
      <DeleteButton
        id="delete-ip-package-button" 
        onClick={() => setDialogOpen(true)}
      >
        Delete Package
      </DeleteButton>
    </React.Fragment>
  );
}

DeleteIpPackageButton.propTypes = {
  packageId: PropTypes.string.isRequired 
};

export default DeleteIpPackageButton;

