
import * as React from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import VerticalLayout from '../layout/VerticalLayout';
import DataTablePagination from './DataTablePagination';
import DataQueryContainer from './DataQueryContainer';
import { columnShape } from './column'

const DataQueryTable = ({ id, queryResponse, data, columns, onRowClick, selectedRowId, resetPageCount }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  React.useEffect(() => {
    setPage(0);
  }, [resetPageCount]);
  return (
    <DataQueryContainer id={id} queryResponse={queryResponse}>
      <VerticalLayout>
        <DataTable id={id}
          isSelectable
          selectedRowId={selectedRowId}
          onRowClick={onRowClick}
          rows={data}
          columns={columns}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
        />    
        <DataTablePagination
          rows={data}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />    
      </VerticalLayout>
    </DataQueryContainer>
  );
};

DataQueryTable.propTypes = {
  id: PropTypes.string,
  queryResponse: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedRowId: PropTypes.string,
  onRowClick: PropTypes.func,
  columns: PropTypes.arrayOf(columnShape).isRequired,
  resetPageCount: PropTypes.number
};

DataQueryTable.defaultProps = {
  resetPageCount: 0
}

export default DataQueryTable;