
import * as React from 'react';
import { useParams } from 'react-router';
import AuthenticatedPage from '../users/authentication/AuthenticatedPage';
import Sections from '../infrastructure/layout/sections/Sections';
import IpPackagesSection from './IpPackagesSection';
import IpPackageItemSection from './IpPackageItemSection';
import AppDrawerItems from '../AppDrawerItems';
          
const IpPackageItemPage = () => {
  let { id } = useParams();

  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
        <IpPackagesSection selectedId={id} />
        <IpPackageItemSection packageId={id}/>
      </Sections>
    </AuthenticatedPage>
  );
}

export default IpPackageItemPage;