import * as React from 'react';
import { maxLengthRule } from '../../../infrastructure/forms/validation';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { ipPackageNameFieldName } from './fields';

const IpPackageNameInput = () => {
  
  return (
    <TextFormField 
      id="ip-package-name-field"
      name={ipPackageNameFieldName}
      label="Package Name"
      variant="outlined"
      required
      fullWidth
      validation={{
        maxLength: maxLengthRule(80)
      }}
    />
  );
};

export default IpPackageNameInput;