import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const PageBox = ({ children }) => (
  <Box sx={{ flexgrow: 1 }}>
    { children }
  </Box>
);

PageBox.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageBox;