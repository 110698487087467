
import * as React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Visible from '../Visible'

const MaximumWidthVisibilty = ({ children, maximumWidth }) => {
    const isBelowMaximum = useMediaQuery(`(max-width:${maximumWidth})`);
    return (
        <Visible isVisible={isBelowMaximum}>{children}</Visible>
    );
}
  
MaximumWidthVisibilty.propTypes = {
  children: PropTypes.node.isRequired,
  maximumWidth: PropTypes.string.isRequired
};

export default MaximumWidthVisibilty;