
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../../infrastructure/layout/tabs/TabPanel';
import MaintainIpPackageLargeGridItem from '../MaintainIpPackageLargeGridItem';
import StatusesEditor from './StatusesEditor';

const MaintainIpPackageItemStatusesTab = ({ currentTabIndex }) =>  {
  return (
    <TabPanel
      currentIndex={currentTabIndex}
      index={2}
    >
      <MaintainIpPackageLargeGridItem>
        <StatusesEditor />
      </MaintainIpPackageLargeGridItem>
    </TabPanel>
  );
}

MaintainIpPackageItemStatusesTab.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
};

export default MaintainIpPackageItemStatusesTab;