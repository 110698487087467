import * as React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from './LoadingIndicator';
import HttpErrorDisplay from './HttpErrorDisplay';
import Visible from '../Visible';

const DataMutationContainer = ({ queryResult, mutationResult, children }) => (
  <React.Fragment>
    <Visible isVisible={queryResult.isLoading || mutationResult.isLoading}>
      <LoadingIndicator />
    </Visible>
    <Visible isVisible={!queryResult.isLoading && !mutationResult.isLoading}>
      { children }    
    </Visible>
    <Visible isVisible={mutationResult.isError}>
      <HttpErrorDisplay queryResponse={mutationResult} />    
    </Visible>
    <Visible isVisible={queryResult.isError}>
      <HttpErrorDisplay queryResponse={queryResult} />    
    </Visible>
  </React.Fragment>
);

DataMutationContainer.propTypes = {
  queryResult: PropTypes.shape(),
  mutationResult: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired
};

DataMutationContainer.defaultProps = {
  queryResult: {
    isLoading: false,
    isError: false
  }
};

export default DataMutationContainer;