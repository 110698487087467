import * as React from 'react';
import PropTypes from 'prop-types';
import HorizontalLayout from '../../../infrastructure/layout/HorizontalLayout';
import VerticalLayout from '../../../infrastructure/layout/VerticalLayout';
import BidForm from './BidForm';
import BidFormLoader from './BidFormLoader';
import BidTypeInput from './BidTypeInput';
import JurisdictionsSelectionTypeInput from './jurisdiction-selection/JurisdictionsSelectionTypeInput';
import SelectJurisdictionsInput from './jurisdiction-selection/SelectJurisdictionsInput';
import AmountInput from './AmountInput';
import WithdrawBidButtons from './WithdrawBidButtons';
import WithdrawBidButtonsVisibility from './WithdrawBidButtonsVisibility';
import PlaceBidButtons from './PlaceBidButtons';
import PlaceBidButtonsVisibility from './PlaceBidButtonsVisibility';
import NoteInput from './NoteInput';

const BidEditor = ({ packageId, selectedBid, onBack, getPackageQueryResponse }) => (
    <BidForm
        packageId={packageId}
        onSubmit={onBack}
        getPackageQueryResponse={getPackageQueryResponse}
    >
        <BidFormLoader packageId={packageId} selectedBid={selectedBid}>
            <VerticalLayout id='bid-editor'>
                <HorizontalLayout>
                    <BidTypeInput
                        packageId={packageId}
                        selectedBid={selectedBid}
                    />
                    <AmountInput />
                    <JurisdictionsSelectionTypeInput packageId={packageId} />
                </HorizontalLayout>
                <NoteInput />
                <SelectJurisdictionsInput
                    packageId={packageId}
                    selectedBid={selectedBid}
                />
                <PlaceBidButtonsVisibility selectedBid={selectedBid}>
                    <PlaceBidButtons
                        onCancel={onBack}
                        selectedBid={selectedBid}
                    />
                </PlaceBidButtonsVisibility>
                <WithdrawBidButtonsVisibility selectedBid={selectedBid}>
                    <WithdrawBidButtons
                        packageId={packageId}
                        selectedBid={selectedBid}
                        onBack={onBack}
                    />
                </WithdrawBidButtonsVisibility>
            </VerticalLayout>
        </BidFormLoader>
    </BidForm>
);

BidEditor.propTypes = {
    packageId: PropTypes.string.isRequired,
    selectedBid: PropTypes.shape().isRequired,
    onBack: PropTypes.func.isRequired,
    getPackageQueryResponse: PropTypes.shape(),
};


export default BidEditor