import * as React from 'react';
import PropTypes from 'prop-types';
import DataCard from './DataCard';
import DataQueryContainer from './DataQueryContainer';
import { fieldShape } from './field'

const DataQueryCard = ({ queryResponse, data, fields, children }) => (
  <DataQueryContainer queryResponse={queryResponse}>
    <DataCard data={data} fields={fields}>
      {children}
    </DataCard>
  </DataQueryContainer>
);

DataQueryCard.propTypes = {
  queryResponse: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(fieldShape)),
  children: PropTypes.node.isRequired  
};

DataQueryCard.defaultProps = {
  fields: []  
};

export default DataQueryCard;