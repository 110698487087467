import * as React from 'react';
import PropTypes from 'prop-types';
import { commaSeparatedToList } from '../infrastructure/helpers';
import SpacedItem from '../infrastructure/layout/SpacedItem';
import JurisdictionItem from './JurisdictionItem';

const SelectedJurisdictions = ({ saleType, field, disabled }) => {
  let jurisdictions = commaSeparatedToList(field.value);
  
  if(jurisdictions.length === 0) {
    return null;
  }

  return jurisdictions.map(
    (jurisdiction, index) => (
      <SpacedItem key={index}>
        <JurisdictionItem
          saleType={saleType}
          field={field}
          jurisdiction={jurisdiction}
          key={index}
          disabled={disabled}
        />
      </SpacedItem>
    )
  );
}

SelectedJurisdictions.propTypes = {
  saleType: PropTypes.string.isRequired,
  field: PropTypes.shape().isRequired,
  disabled: PropTypes.bool
};

export default SelectedJurisdictions;