import { commaSeparatedToList, makeListCommaSeparated } from '../../../../infrastructure/helpers';

export const getSaleTypeApplicableJurisdictionsFieldName = (saleType) =>
  `${saleType}SaleTypeApplicableJurisdictions`

export const getApplicableJurisdictionsFromForm = (saleType, formValues) =>
  commaSeparatedToList(formValues[getSaleTypeApplicableJurisdictionsFieldName(saleType)]);
  
export const setApplicableJurisdictionsToForm = (saleType, applicableJurisdictions, setValue) => 
  setValue(
    getSaleTypeApplicableJurisdictionsFieldName(saleType),
    makeListCommaSeparated(applicableJurisdictions));
