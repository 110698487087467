import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { getPackageById } from '../redux/selectors'
import { parseSaleType } from '../maintenance/sale-types/SaleTypes';
import { parseJurisdictionSelectionType } from '../../jurisdictions/JurisdictionSelectionTypes';

export const getPackageBids = packageId => createSelector(
  getPackageById(packageId),
  ipPackage => {
    return ipPackage.bids.map(bid => ({
      id: bid.id,
      bidType: parseSaleType(bid.bidType),
      amount: bid.amount.value,
      jurisdictionsSelectionType: parseJurisdictionSelectionType(bid.jurisdictionsSelectionType),
      applicableJurisdictions: bid.applicableJurisdictions,
      note: bid.note
    }))
  }
);

const emptyBid = {
  bidType: '',
  amount: 0,
  jurisdictionsSelectionType: '',
  applicableJurisdictions: [],
  note: ''
}

const getBid = (packageId, bidType) => createSelector(
  getPackageBids(packageId),
  bids => bids.find(b => b.bidType === bidType) ?? emptyBid
);

export const useGetPackageBids = packageId => useSelector(getPackageBids(packageId));
export const useGetPackageBid = (packageId, bidType) => useSelector(getBid(packageId, bidType));