import * as React from 'react';
import Sections from '../infrastructure/layout/sections/Sections';
import AuthenticatedPage from './authentication/AuthenticatedPage';
import AppDrawerItems from '../AppDrawerItems';
import UsersSection from './UsersSection'

const UsersListPage = () => (
  <AuthenticatedPage drawerItems={<AppDrawerItems />}>
    <Sections>
      <UsersSection />
    </Sections>
  </AuthenticatedPage>
);

export default UsersListPage;