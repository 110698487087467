import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { getAllPackages } from '../../redux/selectors'

const selectFilterTags = state => state.filterTags;

const isTagSelected = (state, tag) => {
  return state.filterTags.includes(tag);
}

const getAvailableTagsFromAssets = createSelector(
  getAllPackages,
  assets => assets
    .flatMap(asset => asset.tags)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort()
);

const getSelectedTagsFromAssets = createSelector(
  getAvailableTagsFromAssets,
  selectFilterTags,
  (assets, filterTags) => assets
    .filter(tag => filterTags.includes(tag))
);

const getAreAnyTagsFromAssetsSelected = createSelector(
  getSelectedTagsFromAssets,
  assets => assets
    .length > 0
);

const getFilteredAssets = createSelector(
  getAllPackages,
  selectFilterTags,
  (assets, filterTags) => {
    if(filterTags.length === 0) {
      return assets;
    }
    return assets.filter((element) => filterTags.every(filterTag => element.tags.includes(filterTag)));
  }
);

export const useGetFilteredAssets = () => useSelector(getFilteredAssets); 
export const useGetAvailableTagsFromAssets = () => useSelector(getAvailableTagsFromAssets);
export const useGetSelectedTagsFromAssets = () => useSelector(getSelectedTagsFromAssets);
export const useGetAreAnyTagsFromAssetsSelected = () => useSelector(getAreAnyTagsFromAssetsSelected);
export const useIsTagSelected = tag => useSelector(state => isTagSelected(state, tag));