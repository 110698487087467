import PropTypes from 'prop-types';

const Visible = ( { isVisible, children } ) => {
  if(isVisible) {
    return children;
  }
  return null;
}

Visible.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default Visible;