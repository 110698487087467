
import * as React from 'react';
import PropTypes from 'prop-types';
import StatusChip from '../infrastructure/chips/StatusChip';
import ItemInformation from '../infrastructure/ItemInformation';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';
import { getPackageStatusLabel, getPackageStatusDescription } from './IpPackageStatuses';

const IpPackageItemStatus = ({ item }) => (
  <ItemCloudItem key={item.proprietor}>
    <ItemInformation text={getPackageStatusDescription(item.status)}>
        <StatusChip
          id="ip-package-item-status"
          label={getPackageStatusLabel(item.status)}
        />
    </ItemInformation>
  </ItemCloudItem>
);
  
IpPackageItemStatus.propTypes = {
  item: PropTypes.shape().isRequired
};

export default IpPackageItemStatus;