import * as React from 'react';
import { maxLengthRule } from '../../../infrastructure/forms/validation';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { ipPackageProprietorFieldName } from './fields';

const IpPackageProprietorInput = () => {  
  return (
    <TextFormField 
      id="ip-package-proprietor-field"
      name={ipPackageProprietorFieldName}
      label="Proprietor"
      variant="outlined"
      required
      fullWidth
      validation={{
        maxLength: maxLengthRule(50)
      }}
    />
  );
}

export default IpPackageProprietorInput;