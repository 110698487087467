
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../infrastructure/layout/tabs/TabPanel';
import PackagePictureDisplay from './PackagePictureDisplay';

const IpPackageItemPictureTab = ({ packageId, currentTabIndex, item }) =>  (
  <TabPanel
      currentIndex={currentTabIndex}
      index={1}
  >
      <PackagePictureDisplay
          packageId={packageId}
          fileName={item.pictureFileName}
      /> 
  </TabPanel>
);

IpPackageItemPictureTab.propTypes = {
  packageId: PropTypes.string.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  item: PropTypes.shape()
};

export default IpPackageItemPictureTab;