import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { setRequiredTextOnValidationIfRequired } from'./validation';

const DatePickerFormField = ({
  id,
  name,
  required,
  validation = {},
  ...rest
}) => {
  setRequiredTextOnValidationIfRequired(validation, required);

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: {onChange, value, onBlur, ref},
        fieldState: {error},
      }) => {
        if(value === '') {
          value = null;
        }
        return (
          <DatePicker
            value={value}
            onClose={onBlur}
            onBlur={onBlur}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                id={id}
                {...params}
                {...rest}
                required={!!required}
                error={!!error}
                helperText={error ? error.message : ''}
                inputRef={ref}
              />
            )}
          />
        );
      }}
    />
  )
}

DatePickerFormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validation: PropTypes.shape(),
};

DatePickerFormField.defaultProps = {
  required: false,
  validation: {}
};

export default DatePickerFormField;