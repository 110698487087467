
import * as React from 'react';
import PropTypes from 'prop-types';
import Section from '../infrastructure/layout/sections/Section';
import ItemCloud from '../infrastructure/layout/ItemCloud';
import { useGetUserByIdQuery } from '../api';
import { useGetUserById } from './redux';
import UserItemDataCard from './UserItemDataCard';
import UserEmail from './UserEmail';
import UserOrganisation from './UserOrganisation';
import UserTelephone from './UserTelephone';
import UserLinkedInProfile from './UserLinkedInProfile';
import UserActivationButton from './UserActivationButton';

const UserItemSection = ({ id }) =>  {
  const queryResponse = useGetUserByIdQuery(id);
  const data = useGetUserById(id);
  return (
    <Section id="user-item">
      <UserItemDataCard queryResponse={queryResponse} data={data}>
        <ItemCloud>
          <UserEmail user={data}/>
          <UserOrganisation user={data}/>
          <UserTelephone user={data} />
          <UserLinkedInProfile user={data} />
        </ItemCloud>
        <UserActivationButton user={data} />
      </UserItemDataCard>
    </Section>
  );
}

UserItemSection.propTypes = {
  id: PropTypes.string.isRequired
};


export default UserItemSection;