import * as React from 'react';
import UserFilter from './filtering/UserFilter';
import { useGetUsers } from './redux';

const UsersListFilter = () => {
  const users = useGetUsers();
  return (
    <UserFilter data={users} />
  );
};

export default UsersListFilter;