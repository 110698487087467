import * as React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from '../../../infrastructure/data/DeleteButton';
import { saleTypeToServiceFormat } from '../../maintenance/sale-types/SaleTypes';  
import { useWithdrawBidMutation } from '../../../api';

const WithdrawBidButton = ({ packageId, selectedBid, onWithdrawn }) => {    
    const [withdrawBid] = useWithdrawBidMutation();
    const onClick = async () => {
        await withdrawBid({ packageId, bidType: saleTypeToServiceFormat(selectedBid.bidType) }).unwrap();
        onWithdrawn();
    };
    return (
        <DeleteButton
            id="withdraw-bid-button" 
            onClick={onClick}
        >
            Withdraw Bid
        </DeleteButton>
    );
}

WithdrawBidButton.propTypes = {
    packageId: PropTypes.string.isRequired,
    selectedBid: PropTypes.shape().isRequired,
    onWithdrawn: PropTypes.func.isRequired,
};

export default WithdrawBidButton;