
import * as React from 'react';
import HowToReg from '@mui/icons-material/HowToReg';
import PropTypes from 'prop-types';
import ActionButton from '../../../infrastructure/ActionButton'
import { useRegisterInterestMutation } from '../../../api';

const RegisterInterestButton = ({ packageId }) =>  {
  const [register] = useRegisterInterestMutation();
  return (
    <ActionButton 
      id="register-interest-button"
      onClick={() => register({ id: packageId })}
    >
      {<HowToReg />}&nbsp;Register Interest
    </ActionButton>
  );
}

RegisterInterestButton.propTypes = {
  packageId: PropTypes.string.isRequired
};

export default RegisterInterestButton;

