import * as React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../forms/FormContainer';
import DataMutationContainer from './DataMutationContainer';

const BasicDataMutationForm = ({ onSubmit, queryResult, mutationResult, formContext, children }) => (
    <DataMutationContainer
        mutationResult={mutationResult}
        queryResult={queryResult}
    >
        <FormContainer
            formContext={formContext}
            onSuccess={onSubmit}
            mode="onBlur"
        >
            {children}
        </FormContainer>
    </DataMutationContainer>
);

BasicDataMutationForm.propTypes = {
    children: PropTypes.node.isRequired,
    queryResult: PropTypes.shape(),
    mutationResult: PropTypes.shape().isRequired,
    formContext: PropTypes.shape(),
    onSubmit: PropTypes.func.isRequired
};

export default BasicDataMutationForm;