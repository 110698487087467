
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Send from '@mui/icons-material/Send';
import { newPackagePath } from '../../AppRoutes';
import ActionButton from '../../infrastructure/ActionButton'

const AddNewIpPackageButton = () => {
  const navigate = useNavigate();
  return (
    <ActionButton
      id="new-ip-package-button" 
      onClick={() => navigate(newPackagePath, { replace: true })}
    >
      <Send />&nbsp;Add New Package
    </ActionButton>
  );
}

export default AddNewIpPackageButton;

