
import * as React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../infrastructure/data/DataTable';
import { useGetInterest } from '../redux';

const RegisterInterestList = ({ packageId }) => { 
  const interest = useGetInterest(packageId);
  return (
    <DataTable
      id="registered-interest"
      rows={interest}
      page={0}
      rowsPerPage={3}
      columns={([{
        name: '',
        rowLookup: row => row.name
      },
      {
        name: 'Interested',
        rowLookup: row => row.interested
      },
      {
        name: 'Extra details', 
        rowLookup: row => row.extraDetails
      }])}
  />  
  );
}

RegisterInterestList.propTypes = {
  packageId: PropTypes.string.isRequired
};

export default RegisterInterestList;