import * as React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import DrawerContent from './DrawerContent';

const PageDrawer = ({ isOpen, setIsOpen, children }) => {
  return (
      <Drawer
        id="drawer"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DrawerContent setIsOpen={setIsOpen}>
            {children}
        </DrawerContent>
      </Drawer>
  );
}

PageDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    children: PropTypes.node
};

export default PageDrawer;