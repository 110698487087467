import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import SubmitOrCancelFormSection from '../../../infrastructure/forms/SubmitOrCancelFormSection';
import SelectedBidStatus from '../SelectedBidStatus';

const PlaceBidButtons = ({ onCancel, selectedBid }) => {
  const formContext = useFormContext();
  return (
    <SubmitOrCancelFormSection
      id="edit-bid"
      title={selectedBid.status === SelectedBidStatus.New ? "Place bid" : "Revise Bid"}
      formContext={formContext}
      onCancel={onCancel}
    />
  );
}

PlaceBidButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  selectedBid: PropTypes.shape().isRequired
};

export default PlaceBidButtons;