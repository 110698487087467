const IpPackageStatuses = {
    Unavailable: 'unavailable',
    Draft: 'draft',
    Preview: 'preview',
    Active: 'active',
    Closing: 'closing',
    Closed: 'closed',
}

export const parseRawPackageStatus = (rawPackageStatus) => rawPackageStatus.toLowerCase();

export const getPackageStatusLabel = (packageStatus) => {
    switch(packageStatus) {
        case IpPackageStatuses.Unavailable: 
            return 'Unavailable';
        case IpPackageStatuses.Draft: 
            return 'Draft';
        case IpPackageStatuses.Preview: 
            return 'Preview';
        case IpPackageStatuses.Active: 
            return 'Active';
        case IpPackageStatuses.Closing: 
            return 'Closing';
        case IpPackageStatuses.Closed: 
            return 'Closed';
        default:
            return `UNKNOWN package status: ${packageStatus}` 
    }
}

export const getPackageStatusDescription = (packageStatus) => {
    switch(packageStatus) {
        case IpPackageStatuses.Unavailable: 
            return 'Has no status dates set';
        case IpPackageStatuses.Draft: 
            return 'Visible and maintainable to administrative users only';
        case IpPackageStatuses.Preview: 
            return 'Visible to all, interest registration allowed, bids not allowed';
        case IpPackageStatuses.Active: 
            return 'Visible to all, interest registration allowed, bids allowed';
        case IpPackageStatuses.Closing: 
            return 'Visible to administrative users only, sales being brokered and closed';
        case IpPackageStatuses.Closed: 
            return 'Visible to administrative users only, sales process finished';
        default:
            return `UNKNOWN package status: ${packageStatus}` 
    }
}

export default IpPackageStatuses;