
import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form'
import Visible from '../../infrastructure/Visible';

const NotNewOrDirtyVisibility = ({ isNew, children }) =>  {
  const { isDirty } = useFormState();
  return (
    <Visible isVisible={!isNew && !isDirty}>
      {children}
    </Visible>
  );
}

NotNewOrDirtyVisibility.propTypes = {
  isNew: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

NotNewOrDirtyVisibility.defaultProps = {
  isNew: false
};

export default NotNewOrDirtyVisibility;

