
import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import VerticalLayout from '../../../infrastructure/layout/VerticalLayout';
import SaleTypes from '../../maintenance/sale-types/SaleTypes';
import RegisterInterestForm from './RegisterInterestForm';
import RegisterInterestInformation from './RegisterInterestInformation';
import RegisterInterestItem from './RegisterInterestItem';
import RegisterInterestButtons from './RegisterInterestButtons';
import { useGetInterestMappedToFormValues } from '../redux';

const RegisterInterestGrid = ({ children }) => <Grid container spacing={2}>{children}</Grid>;
const RegisterInterestLargeGridItem = ({ children }) => <Grid item xs={12}>{children}</Grid>;

const RegisterInterestSection = ({ packageId, onClose }) => {
  const interest = useGetInterestMappedToFormValues(packageId);
  const formContext = useForm({
    defaultValues: interest
  });

  return (  
    <VerticalLayout id="register-interest-section">
      <RegisterInterestForm
        packageId={packageId}
        formContext={formContext}
        onSubmit={onClose}
      >
        <RegisterInterestGrid>
          <RegisterInterestLargeGridItem>
            <RegisterInterestInformation />
          </RegisterInterestLargeGridItem>
          <RegisterInterestItem saleType={SaleTypes.Assignment} />
          <RegisterInterestItem saleType={SaleTypes.ExclusiveLicense} />
          <RegisterInterestItem saleType={SaleTypes.NonExclusiveLicense} />
          <RegisterInterestLargeGridItem>
            <RegisterInterestButtons
              formContext={formContext}
              onCancel={onClose}
            />
          </RegisterInterestLargeGridItem>
        </RegisterInterestGrid>
      </RegisterInterestForm>
    </VerticalLayout>
  );
}

RegisterInterestSection.propTypes = {
  packageId: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

RegisterInterestSection.defaultProps = {
  onClose: undefined
};

export default RegisterInterestSection;