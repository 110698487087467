
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'

const DialogButton = ({ id, children, onClick, autoFocus }) =>(
    <Button 
        id={id}
        variant="dialog"
        onClick={onClick}
        autoFocus
    >
        {children} 
    </Button>
);

DialogButton.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
};

export default DialogButton;
