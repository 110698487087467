import * as React from 'react';
import PropTypes from 'prop-types';
import DataQueryTable from '../../infrastructure/data/DataQueryTable';
import { columnFormat } from '../../infrastructure/data/column';

const UserLogsList = ({ queryResponse, data }) => (
  <DataQueryTable
    id="user-logs-list"
    queryResponse={queryResponse}
    data={data}
    columns={([{
        name: 'Action',
        rowLookup: row => row.userLogType
      },
      {
        name: 'Action By',
        rowLookup: row => row.actionBy
      },
      {
        name: 'Date',
        format: columnFormat.DATE,
        rowLookup: row => row.timestamp
      }
    ])}
  />
);

UserLogsList.propTypes = {
  queryResponse: PropTypes.shape().isRequired,
  data: PropTypes.array.isRequired
};

export default UserLogsList;