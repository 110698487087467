import { createSlice } from '@reduxjs/toolkit'
import { selectTag } from '../filtering/redux/slices';

const slice = createSlice({
  name: 'packages',
  initialState: { resetPageCount: 0 },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(selectTag, (state, _action) => {
      state.resetPageCount++;
    });
  },
});

export const { changePage, setRowsPerPage } = slice.actions
export default slice.reducer