import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const Sections = ({ children }) => (
  <Grid container spacing={3}>
    { children }
  </Grid>
);

Sections.propTypes = {
  children: PropTypes.node.isRequired
};

export default Sections;