import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_agoraip_mercury_signup_signin",
        editProfile: "B2C_1_agoraip_mercury_profile_edit"
    },
    authorities: {
        signUpSignIn: {
            authority: `${process.env.REACT_APP_INSTANCE}/${process.env.REACT_APP_DOMAIN}/B2C_1_agoraip_mercury_signup_signin`,
        },
        editProfile: {
            authority: `${process.env.REACT_APP_INSTANCE}/${process.env.REACT_APP_DOMAIN}/B2C_1_agoraip_mercury_profile_edit`
        }
    },
    authorityDomain: process.env.REACT_APP_INSTANCE
}


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.info(message);
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: [`https://${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_API_CLIENT_ID}/access_as_user`]
};