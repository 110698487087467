import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Visible from './Visible';

const ImageAvatar = ({ id, imageUrl, onClick }) => {
    return (
        <>
            <Visible isVisible={!!imageUrl}>
                <Box
                    component="img"
                    id={`${id}-image-avatar`}
                    src={imageUrl}
                    onClick={onClick}
                    sx={{ maxWidth: 300, maxHeight: 300 }}
                />
            </Visible>

            <Visible isVisible={!imageUrl}>
                <InsertPhotoIcon sx={{ width: 100, height: 100 }} />
            </Visible>
        </>
    );
}

ImageAvatar.propTypes = {
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default ImageAvatar;