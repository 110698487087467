
import * as React from 'react';
import PropTypes from 'prop-types';

const FileUploadInput = ({ id, accept, onChange, forwardedRef }) => {
    const createFileItem = (file) => ({
        name: file.name,
        actualFile: file
    });

    return (
        <input
            ref={forwardedRef}
            id={`${id}-input`}
            accept={accept}
            type="file"
            style={{ display: "none" }}
            onChange={e => {
                const file = createFileItem(e.target.files[0]);
                onChange(file);
            }}
        />       
    );
}
    
FileUploadInput.propTypes = {
    id: PropTypes.string,
    accept: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    forwardedRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })
    ])
};

export default FileUploadInput;
