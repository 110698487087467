
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import BasicDataMutationForm from '../../infrastructure/data/BasicDataMutationForm';
import { maintainPackagePath, bindIdToPath } from '../../AppRoutes'
import * as generalFields from './general/fields';
import * as saleTypeFields from './sale-types/fields';
import * as statusesFields from './statuses/fields';
import { useSavePackageMutation } from '../../api';

const IpPackageMaintenanceForm = ({ packageId, getPackageQueryResult, children }) => {
  const [savePackage, result] = useSavePackageMutation();
  const navigate = useNavigate(); 
   
  const handleSubmit = async (data) => {
    const post = {
      id: packageId, 
      name: data[generalFields.ipPackageNameFieldName],
      description: data[generalFields.ipPackageDescriptionFieldName],
      proprietor: data[generalFields.ipPackageProprietorFieldName],
      tags: generalFields.getTagsFromForm(data),
      saleTypesConfiguration: saleTypeFields.getSalesTypesConfigurationFromForm(data),
      statuses: statusesFields.getStatusesFromForm(data),
    };
    await savePackage(post).unwrap();
    
    navigate(bindIdToPath(maintainPackagePath, packageId));
  }
  
  return (
    <BasicDataMutationForm 
      mutationResult={result}
      queryResult={getPackageQueryResult}
      onSubmit={handleSubmit}>
      {children}
    </BasicDataMutationForm>
  );
}

IpPackageMaintenanceForm.propTypes = {
  packageId: PropTypes.string.isRequired,
  getPackageQueryResult: PropTypes.shape(),
  children: PropTypes.node.isRequired
};

export default IpPackageMaintenanceForm;