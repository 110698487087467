import * as React from 'react';
import FooterText from '../../text/FooterText';
import HelloLink from '../../text/HelloLink';

const PageFooter = () => (
  <React.Fragment>
  <FooterText spaced>
    {'©'} {new Date().getFullYear()} {' - Agora IP Limited | '}
    <HelloLink />
  </FooterText>
  <FooterText>
    Agora IP Limited | Castle Hill House, 12 Castle Hill, Windsor, SL4 1PD, United Kingdom
  </FooterText>
</React.Fragment>
);

export default PageFooter;