import PropTypes from 'prop-types';

export const fieldShape = PropTypes.shape({
    isTitle: PropTypes.string,
    dataLookup: PropTypes.func.isRequired
}).isRequired;

export const fieldDefaults = {
    isTitle: false
};
