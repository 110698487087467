import * as React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../../../infrastructure/ActionButton';
import { useGetCanAddBid } from './redux';

const AddBidButton = ({ packageId, onAddBid }) => { 
  const canBid = useGetCanAddBid(packageId);
  return (
    <ActionButton
      id="add-bid-button"
      onClick={onAddBid}
      disabled={!canBid}
    >
      Add Bid
    </ActionButton>
  );
}

AddBidButton.propTypes = {
  packageId: PropTypes.string.isRequired,
  onAddBid: PropTypes.func.isRequired
};

export default AddBidButton;