import PropTypes from 'prop-types';
import { boolToYesNo } from '../helpers';

export const columnFormat = {
    STRING: 0,
    DATE: 1,
    YESNO: 2,
    CUSTOM: 3,
}

export const columnShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    format: PropTypes.oneOf([columnFormat.STRING, columnFormat.DATE, columnFormat.YESNO, columnFormat.CUSTOM]),
    customCellContentCreator: PropTypes.func,
    rowLookup: PropTypes.func
});

export const format = (column, row) => {
    if(column.format === columnFormat.DATE) {
        const value = column.rowLookup(row);
        return new Date(value).toLocaleString();
    }
    if(column.format === columnFormat.YESNO) {
        const value = column.rowLookup(row);
        return boolToYesNo(value);
    }
    if(column.format === columnFormat.CUSTOM) {
        return column.customCellContentCreator(row);
    }
    return column.rowLookup(row);
}
