import { createSlice  } from '@reduxjs/toolkit'
import * as packageMaintenanceActions from '../../maintenance/redux/actions'

const slice = createSlice({
  name: 'filterTags',
  initialState: [],
  reducers: {
    selectTag: (
      state, { payload: { tag } }
    ) => {
      if(state.includes(tag)) {
        const index = state.indexOf(tag);
        if (index > -1) {
          state.splice(index, 1);
        }
      } else {
        state.push(tag);
      }
    },
    clearTags: (
      state
    ) => {
      state.length = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(packageMaintenanceActions.packageSaved, (state, _action) => {
      state.length = 0;
    });
    builder.addCase(packageMaintenanceActions.packageDeleted, (state, _action) => {
      state.length = 0;
    });
  },
})

export const { selectTag, clearTags } = slice.actions
export default slice.reducer
  