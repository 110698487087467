
import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../infrastructure/Visible';

const NotNewVisibility = ({ isNew, children }) =>  {
  return (
    <Visible isVisible={!isNew}>
      {children}
    </Visible>
  );
}

NotNewVisibility.propTypes = {
  isNew: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

NotNewVisibility.defaultProps = {
  isNew: false
};

export default NotNewVisibility;

