import * as React from 'react';
import UserFilter from '../../../users/filtering/UserFilter';
import { useGetBids } from './redux';

const BidsListFilter = () => {
  const bids = useGetBids();
  return (
    <UserFilter data={bids} />
  );
};
export default BidsListFilter;