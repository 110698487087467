import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { ipApi } from '../../../api';
import { parseJurisdictionSelectionType, getJurisdictionSelectionTypeLabel } from '../../../jurisdictions/JurisdictionSelectionTypes';
import { makeListCommaSeparated } from '../../../infrastructure/helpers';

const selectBidHistoryResult = bidId => ipApi.endpoints.getBidHistory.select(bidId);

const getRawBidHistory = bidId => createSelector(
  selectBidHistoryResult(bidId),
  bidHistoryResult => {
    const result = bidHistoryResult?.data ?? { data: [] };
    return result.data;
  }
);

const getBidHistoryById = bidId => createSelector(
  getRawBidHistory(bidId),
  bidHistory => bidHistory.map(bid => ({
    ...bid,
    amount: bid.amount?.value ?? '',
    jurisdictionsSelectionType: 
      bid.jurisdictionsSelectionType ? 
        getJurisdictionSelectionTypeLabel(parseJurisdictionSelectionType(bid.jurisdictionsSelectionType))
        : '',
    applicableJurisdictions: 
      bid.applicableJurisdictions
        ? makeListCommaSeparated(bid.applicableJurisdictions)
        : ''
  }))
);

export const useGetBidHistoryById = (bidId) => useSelector(getBidHistoryById(bidId));