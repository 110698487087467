
import * as React from 'react';
import PropTypes from 'prop-types';
import CentreBox from './layout/CentreBox';
import ImageAvatar from './ImageAvatar';
import ImageViewer from './ImageViewer';

const ImageDisplay = ({ id, getPictureFile, fileName }) =>  {
  const [showViewer, setShowViewer] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(undefined);

  React.useEffect(() => {    
    async function fetchImage() {
      const response = await getPictureFile();
      return await response.data;
    }
    
    if(!fileName) {
      setImageUrl(undefined);
      return;
    }

    fetchImage()
      .then(imageData => setImageUrl(URL.createObjectURL(imageData)));
  }, [getPictureFile, fileName]);
  
  return (
    <CentreBox>
      <ImageAvatar
        id={id}
        imageUrl={imageUrl}
        onClick={() => setShowViewer(true)}
      />
      <ImageViewer
        imageUrl={imageUrl}
        open={imageUrl !== undefined && showViewer}
        onClose={() => setShowViewer(false)}
      />
    </CentreBox>
  );
}

ImageDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  getPictureFile: PropTypes.func.isRequired,
};

export default ImageDisplay;