
import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack'

const VerticalLayout = ({ id, children }) => (
  <Stack id={id} spacing={2}>{children}</Stack>
);
  
VerticalLayout.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
};

export default VerticalLayout;