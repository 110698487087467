import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ActionButton from '../infrastructure/ActionButton';

const clearSelectedJurisdictions = (field) => field.onChange('');

const JurisdictionsPopup = ({ field, isOpen, onClose, children }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      {children}
      <DialogActions>
        <ActionButton
          id="clear-selected-jurisdictions-button"
          onClick={() => clearSelectedJurisdictions(field)}
        >
            Clear
        </ActionButton>
        <ActionButton
          id="close-select-jurisdictions-button"
          onClick={onClose}
        >
            Close
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}

JurisdictionsPopup.propTypes = {
  field: PropTypes.shape().isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default JurisdictionsPopup;