import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { setRequiredTextOnValidationIfRequired } from './validation';

const SimpleAutoCompleteFormField = ({
  id,
  name,
  fullWidth,
  options,
  defaultValue,
  onValueChange,
  required,
  validation,
  disabled,
  ...rest
}) => {
  setRequiredTextOnValidationIfRequired(validation, required);

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            fullWidth={fullWidth}
            disabled={disabled}
            disableClearable
            disablePortal
            defaultValue={defaultValue}
            value={value}
            options={options}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            onChange={(_event, value) => {
              if(onValueChange) {
                onValueChange(value);
              }
              onChange(value);
            }}
            onBlur={onBlur}
            renderInput={(params) => (
              <TextField
                id={id}
                name={name}
                required={required}
                {...rest}
                {...params}
                error={!!error}
                helperText={error ? error.message : ''}
                inputRef={ref}
              />
            )}
          />
        )
      }}
    />
  );
}

SimpleAutoCompleteFormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  defaultValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  onValueChange: PropTypes.func,
  required: PropTypes.bool,
  validation: PropTypes.shape(),
  disabled: PropTypes.bool
};

SimpleAutoCompleteFormField.defaultProps = {
  fullWidth: false,
  required: false,
  validation: {}
};


export default SimpleAutoCompleteFormField;