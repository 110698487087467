
import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import ActionButton from '../../infrastructure/ActionButton'
import { logout } from './helpers';

const LogoutButton = () =>  {
  const msal = useMsal();
  return (
    <ActionButton 
      id="logoutButton"
      onClick={() => logout(msal)}
    >
      Logout
    </ActionButton>
  );
}

export default LogoutButton;

