import * as React from 'react';
import ItemCloud from '../../infrastructure/layout/ItemCloud';
import ItemCloudItem from '../../infrastructure/layout/ItemCloudItem';
import TagFilterItem from './TagFilterItem';
import { useGetAvailableTagsFromAssets } from './redux/selectors';

const renderItems = tags => tags.map(
  tag => (
    <ItemCloudItem key={tag}>
      <TagFilterItem tag={tag} />
    </ItemCloudItem>
  )
);

const FullTagCloud = () => {
  let tags = useGetAvailableTagsFromAssets();
  
  return (
    <ItemCloud>{renderItems(tags)}</ItemCloud>
  );
}

export default FullTagCloud;