
import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form'
import Visible from '../../infrastructure/Visible';

const NewOrDirtyVisibility = ({ isNew, children }) =>  {
  const { isDirty } = useFormState();
  return (
    <Visible isVisible={isNew || isDirty}>
      {children}
    </Visible>
  );
}

NewOrDirtyVisibility.propTypes = {
  isNew: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

NewOrDirtyVisibility.defaultProps = {
  isNew: false
};

export default NewOrDirtyVisibility;

