import { useMsal } from "@azure/msal-react";
import PropTypes from 'prop-types';
import Visible from '../../infrastructure/Visible';
import { hasUserGotAnyRolesFromList } from "./helpers";

const UserRoleVisibility = ({ children, roles }) => {  
  const msal = useMsal();
  const hasRoles = hasUserGotAnyRolesFromList(msal, roles)
  return (
    <Visible isVisible={hasRoles}>
        {children}
    </Visible>
    );
};

UserRoleVisibility.propTypes = {
    children: PropTypes.node.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default UserRoleVisibility;