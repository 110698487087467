import * as React from 'react';
import PropTypes from 'prop-types';
import Section from '../../../infrastructure/layout/sections/Section';
import { useGetBidHistoryQuery } from '../../../api';
import { useGetBidHistoryById } from './redux';
import BidHistory from './BidHistory';
import SectionTitle from '../../../infrastructure/text/SectionTitle';

const BidHistorySection = ({ bidId }) => {
  const queryResponse = useGetBidHistoryQuery(bidId);
  const data = useGetBidHistoryById(bidId);
  return (
    <Section id="bid-history">
      <SectionTitle>Bid History</SectionTitle>
      <BidHistory queryResponse={queryResponse} data={data} />
    </Section>
  );
}

BidHistorySection.propTypes = {
  bidId: PropTypes.string.isRequired
};

export default BidHistorySection;