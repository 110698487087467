import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

const PageForegroundBox = ({ children }) => (
  <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Toolbar />
    { children }
  </Container>
);

PageForegroundBox.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageForegroundBox;