import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
      
const Section = ({ id, children }) => (
  <Grid item xs={12} id={id}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      { children }
    </Paper>
  </Grid>
);

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Section;