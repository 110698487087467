
import * as React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { getPackageStatusLabel } from '../../IpPackageStatuses'
import StatusActiveFromInput from './StatusActiveFromInput';
import StatusDescription from './StatusDescription';

const ExtraStatusSmallGridItem = ({ children }) => <Grid item xs={1}>{children}</Grid>;
const StatusSmallGridItem = ({ children }) => <Grid item xs={3}>{children}</Grid>;
const StatusMediumGridItem = ({ children }) => <Grid item xs={8}>{children}</Grid>;
const StatusLargeGridItem = ({ children }) => <Grid item xs={12}>{children}</Grid>
const StatusTitle = ({ status }) => <p>{getPackageStatusLabel(status)}</p>;

const StatusEditorItem = ({ status }) => {
  return (
    <React.Fragment>
      <ExtraStatusSmallGridItem>
        <StatusTitle status={status} />
      </ExtraStatusSmallGridItem>
      <StatusSmallGridItem>
        <StatusActiveFromInput status={status} />
      </StatusSmallGridItem>
      <StatusMediumGridItem>
        <StatusDescription status={status} />
      </StatusMediumGridItem>
      <StatusLargeGridItem>
        <Divider />
      </StatusLargeGridItem>
    </React.Fragment>
  );
}

StatusEditorItem.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusEditorItem;