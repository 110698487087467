import PropTypes from 'prop-types';
import Sections from '../../infrastructure/layout/sections/Sections';
import Section from '../../infrastructure/layout/sections/Section';
import SectionTitle from '../../infrastructure/text/SectionTitle';
import CentreBox from '../../infrastructure/layout/CentreBox';
import Page from '../../infrastructure/layout/pages/Page';
import ActionButton from '../../infrastructure/ActionButton';

const AuthenticationErrorPage = ({ error }) => {
    console.error(error);
    return (
        <Page>
            <Sections>
                <Section>
                    <SectionTitle>Login/Sign-up Error</SectionTitle>
                    <CentreBox>
                        Something went wrong while trying to login/sign-up! <br/>
                        Please contact your administrator if the problem persists.
                    </CentreBox>
                    <CentreBox><ActionButton onClick={() => window.location.reload()}>Go back to the Login page</ActionButton></CentreBox>
                </Section>
            </Sections>
        </Page>
    )
};

AuthenticationErrorPage.propTypes = {
    error: PropTypes.any.isRequired
};
  
export default AuthenticationErrorPage;