
import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

const ItemCloud = ({ children }) => (
  <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 1.0,
        m: 1.0,
      }}
      component="ul"
    >
      {children}
    </Paper>
);
  
ItemCloud.propTypes = {
  children: PropTypes.node
};

export default ItemCloud;