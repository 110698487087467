
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../infrastructure/layout/tabs/TabPanel';
import NoInterestVisibility from './no-interest/NoInterestVisibility';
import NoInterestSection from './no-interest/NoInterestSection';
import HasInterestVisibility from './HasInterestVisibility';
import HasNatureOfInterestSubmittedVisibility from './HasNatureOfInterestSubmittedVisibility';
import RegisterInterestSection from './editing/RegisterInterestSection';
import RegisterInterestViewSection from './viewing/RegisterInterestViewSection';

const IpPackageItemRegisteredInterestTab = ({ currentTabIndex, packageId }) => {
  const [isEditable, setIsEditable] = React.useState(false);

  return (
    <TabPanel currentIndex={currentTabIndex} index={2}>
      <NoInterestVisibility packageId={packageId}>
        <NoInterestSection packageId={packageId} />
      </NoInterestVisibility>
      <HasInterestVisibility packageId={packageId}>
        <RegisterInterestSection packageId={packageId} />
      </HasInterestVisibility>
      <HasNatureOfInterestSubmittedVisibility packageId={packageId} disabled={isEditable}>
        <RegisterInterestViewSection packageId={packageId} onEdit={() => setIsEditable(true)} />
      </HasNatureOfInterestSubmittedVisibility>
      <HasNatureOfInterestSubmittedVisibility packageId={packageId} disabled={!isEditable}>
        <RegisterInterestSection packageId={packageId} onClose={() => setIsEditable(false)} />
      </HasNatureOfInterestSubmittedVisibility>
    </TabPanel>
  );
}

IpPackageItemRegisteredInterestTab.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  packageId: PropTypes.string.isRequired
};

export default IpPackageItemRegisteredInterestTab;