
import * as React from 'react'
import PropTypes from 'prop-types';
import DeleteConfirmationDialog from '../../infrastructure/dialogs/DeleteConfirmationDialog';

const DeleteIpPackageDialog = ({ isOpen, onClose, onDelete }) => {
    return (
      <DeleteConfirmationDialog
        id="delete-ip-package-dialog"
        title="Delete this Package?"
        bodyText="Click 'Delete' to continue to remove this package. The action is irreversible."
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDelete}
      />
  );
}

DeleteIpPackageDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteIpPackageDialog;

