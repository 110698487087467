
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import Section from '../infrastructure/layout/sections/Section';
import SectionTitle from '../infrastructure/text/SectionTitle';
import IpPackageList from './IpPackageList';
import TagFilterSection from './filtering/TagFilterSection';
import { useGetPackagesQuery } from '../api';

const IpPackagesSection = ({ selectedId }) => { 
  const response = useGetPackagesQuery();
  const navigate = useNavigate();
  return (
    <Section>
      <SectionTitle>Available IP Packages</SectionTitle>
      <TagFilterSection />
      <IpPackageList
        selectedId={selectedId}
        response={response}
        onPackageSelected={(id) => navigate(`/${id}`)}
      />
    </Section>
  );
}


IpPackagesSection.propTypes = {
  selectedId: PropTypes.string
};

export default IpPackagesSection;