
import * as React from 'react';
import InformationAlert from '../../../infrastructure/InformationAlert';

const ReadOnlySaleTypesInformation = () => ( 
  <InformationAlert
    id="ip-package-sale_types-read-only-information"
    text="Sale types cannot be modified. This is probably because bids have been placed against the package"
  />
);

export default ReadOnlySaleTypesInformation;

