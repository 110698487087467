
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const CentreBox = ({ children }) => (
  <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        p: 1.0,
        m: 1.0,
      }}
      component="div"
    >
      {children}
    </Box>
);
  
CentreBox.propTypes = {
  children: PropTypes.node
};

export default CentreBox;