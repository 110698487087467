import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Visible from '../../../infrastructure/Visible';
import SelectedBidStatus from '../SelectedBidStatus';

const WithdrawBidButtonsVisibility = ({ children, selectedBid }) => {
    const formContext = useFormContext();
    return (
        <Visible isVisible={selectedBid.status !== SelectedBidStatus.New && !formContext.formState.isDirty}>{children}</Visible>
    );
}

WithdrawBidButtonsVisibility.propTypes = {
    children: PropTypes.node.isRequired,
    selectedBid: PropTypes.shape().isRequired
};

export default WithdrawBidButtonsVisibility;