
import * as React from 'react';
import Grid from '@mui/material/Grid';
import VerticalLayout from '../../../infrastructure/layout/VerticalLayout';
import IpPackageStatuses from '../../IpPackageStatuses';
import StatusEditorItem from './StatusEditorItem';

const StatusesGrid = ({ children }) => <Grid container spacing={2}>{children}</Grid>

const StatusesEditor = () => (
  <VerticalLayout>
    <StatusesGrid>
      <StatusEditorItem status={IpPackageStatuses.Draft} />
      <StatusEditorItem status={IpPackageStatuses.Preview} />
      <StatusEditorItem status={IpPackageStatuses.Active} />
      <StatusEditorItem status={IpPackageStatuses.Closing} />
      <StatusEditorItem status={IpPackageStatuses.Closed} />
    </StatusesGrid>
  </VerticalLayout>
);

StatusesEditor.propTypes = {
};

export default StatusesEditor;