
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../infrastructure/layout/tabs/TabPanel';
import Visible from '../../infrastructure/Visible';
import BidsList from './viewing/BidsList';
import BidViewInformation from './viewing/BidViewInformation';
import BidEditor from './editing/BidEditor';
import AddBidButton from './editing/AddBidButton';
import SelectedBidStatus from './SelectedBidStatus';

const createUnselectedBid = () => ({ status: SelectedBidStatus.Unselected });
const createNewSelectedBid = () => ({ status: SelectedBidStatus.New });
const createSelectedBid = (bidType) => ({ bidType, status: SelectedBidStatus.Selected });

const IpPackageItemBidsTab = ({ currentTabIndex, packageId, getPackageQueryResponse }) => {
  const [selectedBid, selectBid] = React.useState(createUnselectedBid());

  return (
    <TabPanel currentIndex={currentTabIndex} index={3}>    
      <Visible isVisible={selectedBid.status === SelectedBidStatus.Unselected}>
        <BidViewInformation />
        <BidsList
          packageId={packageId}
          onSelectBid={bid => selectBid(createSelectedBid(bid.bidType))}
        />
        <AddBidButton
          packageId={packageId}
          onAddBid={() => selectBid(createNewSelectedBid())}
        />
      </Visible>
      <Visible isVisible={selectedBid.status !== SelectedBidStatus.Unselected}>
        <BidEditor
          packageId={packageId}
          selectedBid={selectedBid}
          onBack={() => selectBid(createUnselectedBid())}
          getPackageQueryResponse={getPackageQueryResponse}
        />
      </Visible>
    </TabPanel>
  );
}

IpPackageItemBidsTab.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  packageId: PropTypes.string.isRequired,  
  getPackageQueryResponse: PropTypes.shape(),
};

export default IpPackageItemBidsTab;