
import * as React from 'react';
import PropTypes from 'prop-types';
import ProprietorChip from '../infrastructure/chips/ProprietorChip';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';

const UserOrganisation = ({ user }) => (
  <ItemCloudItem key={user.proprietor}><ProprietorChip label={user.organisation ?? 'None'} /></ItemCloudItem>
);
  
UserOrganisation.propTypes = {
  user: PropTypes.shape().isRequired
};

export default UserOrganisation;