
import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormValues } from '../../../../infrastructure/forms/hooks';
import Visible from '../../../../infrastructure/Visible';
import { getUseAllJurisdictionsFromForm, getUseSelectedJurisdictionsFromForm } from '../fields';

const SelectJurisdictionsVisibility = ({ saleType, children }) =>  {
  const formValues = useFormValues();
  return (
    <Visible isVisible={getUseAllJurisdictionsFromForm(saleType, formValues) || getUseSelectedJurisdictionsFromForm(saleType, formValues)}>
      {children}
    </Visible>
  );
}

SelectJurisdictionsVisibility.propTypes = {
  saleType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SelectJurisdictionsVisibility;

