import * as React from 'react';
import PropTypes from 'prop-types';
import Theme from '../../themes/Theme';
import PageToolBar from './toolbar/PageToolBar';
import PageBox from './PageBox';
import PageBackgroundBox from './PageBackgroundBox';
import PageForegroundBox from './PageForegroundBox';
import PageFooter from './PageFooter';
import PageDrawer from './drawer/PageDrawer';
import Visible from '../../Visible';

const Page = ({ children, drawerItems }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <Theme>
      <Visible isVisible={drawerItems !== undefined}>
        <PageDrawer
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
        >
          {drawerItems}
        </PageDrawer>
      </Visible>
      <PageBox>
        <PageToolBar
          showMenu={drawerItems !== undefined}
          onMenuButtonClick={() => setIsDrawerOpen(true)}
        />
        <PageBackgroundBox>
          <PageForegroundBox>
            { children }
            <PageFooter />
          </PageForegroundBox>
        </PageBackgroundBox>
      </PageBox>
    </Theme>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  drawerItems: PropTypes.node
};

Page.defaultProps = {
  drawerItems: undefined
}
export default Page;