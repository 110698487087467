import * as React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const MenuButton = ({ onClick }) =>(
  <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="menu"
    sx={{ mr: 2 }}
    onClick={onClick}
  >
    <MenuIcon color='primary' />
  </IconButton>
);

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MenuButton;