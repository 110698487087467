import * as React from 'react';
import PropTypes from 'prop-types';
import BasicDataMutationForm from '../../../infrastructure/data/BasicDataMutationForm';
import { jurisdictionSelectionTypeToServiceFormat } from '../../../jurisdictions/JurisdictionSelectionTypes';
import { useSaveBidMutation } from '../../../api';
import * as fields from './fields';
import { saleTypeToServiceFormat } from '../../maintenance/sale-types/SaleTypes';
import { commaSeparatedToList } from '../../../infrastructure/helpers';

const BidForm = ({ packageId, onSubmit, getPackageQueryResult, children}) => {
  const [saveBid, result] = useSaveBidMutation();
  
  const handleSubmit = async (data) => {
    const bid = {
      ipPackageId: packageId,
      jurisdictionsSelectionType: jurisdictionSelectionTypeToServiceFormat(data[fields.jurisdictionsSelectionTypeFieldName]),
      amount: data[fields.bidAmountFieldName],
      bidType: saleTypeToServiceFormat(data[fields.bidTypeFieldName]),
      applicableJurisdictions: commaSeparatedToList(data[fields.applicableJurisdictionsFieldName]),
      note: data[fields.noteFieldName],
    };    
    await saveBid(bid).unwrap();
    onSubmit();
  }

  return (
    <BasicDataMutationForm
      mutationResult={result}
      queryResult={getPackageQueryResult}
      onSubmit={handleSubmit}>
      {children}
    </BasicDataMutationForm>
  );
}

BidForm.propTypes = {
  packageId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getPackageQueryResult: PropTypes.shape(),
  children: PropTypes.node.isRequired
};

export default BidForm;