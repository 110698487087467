import SaleTypes from './SaleTypes';
import * as jurisdictionSelectionFields from './jurisdiction-selection/fields';

export const getUseAllJurisdcitionsFieldName = (saleType) => `${saleType}SaleTypeUseAllJurisdictions`;
export const getUseSelectedJurisdcitionsFieldName = (saleType) => `${saleType}SaleTypeUseSelectedJurisdictions`;
export const getUseAllJurisdictionsFromForm = (saleType, formValues) => formValues[getUseAllJurisdcitionsFieldName(saleType)];
export const getUseSelectedJurisdictionsFromForm = (saleType, formValues) => formValues[getUseSelectedJurisdcitionsFieldName(saleType)];
export const setUseAllJurisdictionsToForm = (saleType, value, setValue) => setValue(getUseAllJurisdcitionsFieldName(saleType), value);
export const setUseSelectedJurisdictionsToForm = (saleType, value, setValue) => setValue(getUseSelectedJurisdcitionsFieldName(saleType), value);

const getSalesTypeConfigurationFromForm = (saleType, formValues) => ({
  useAllJurisdictions: getUseAllJurisdictionsFromForm(saleType, formValues),
  useSelectedJurisdictions: getUseSelectedJurisdictionsFromForm(saleType, formValues),
  applicableJurisdictions: jurisdictionSelectionFields.getApplicableJurisdictionsFromForm(saleType, formValues)
}); 

export const getSalesTypesConfigurationFromForm = (formValues) => ({
  assignment: getSalesTypeConfigurationFromForm(SaleTypes.Assignment, formValues),
  exclusiveLicense: getSalesTypeConfigurationFromForm(SaleTypes.ExclusiveLicense, formValues),
  nonExclusiveLicense: getSalesTypeConfigurationFromForm(SaleTypes.NonExclusiveLicense, formValues),
});

const setSaleTypeConfigurationFormFields = (saleType, salesType, setValue) => {
  setUseAllJurisdictionsToForm(saleType, salesType.useAllJurisdictions, setValue);
  setUseSelectedJurisdictionsToForm(saleType, salesType.useSelectedJurisdictions, setValue);
  jurisdictionSelectionFields.setApplicableJurisdictionsToForm(saleType, salesType.applicableJurisdictions, setValue);   
}

export const setSaleTypesConfigurationFormFields = (assignment, exclusiveLicense, nonExclusiveLicense, setValue) => {
  setSaleTypeConfigurationFormFields(SaleTypes.Assignment, assignment, setValue);
  setSaleTypeConfigurationFormFields(SaleTypes.ExclusiveLicense, exclusiveLicense, setValue);
  setSaleTypeConfigurationFormFields(SaleTypes.NonExclusiveLicense, nonExclusiveLicense, setValue);
}
