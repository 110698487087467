
import * as React from 'react';
import { useFormState } from 'react-hook-form'
import Send from '@mui/icons-material/Send';
import SubmitButton from '../../infrastructure/data/SubmitButton'

const SaveIpPackageButton = () => {
  const { isDirty, errors } = useFormState();
  return (
    <SubmitButton
      id="save-ip-package-button"
      disabled={!isDirty || Object.keys(errors).length > 0}
    >
        <Send />&nbsp;Save Package
    </SubmitButton> 
  );
}

export default SaveIpPackageButton;

