import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import StandardOptionAutoCompleteFormField from '../../../infrastructure/forms/StandardOptionAutoCompleteFormField';
import { useGetAllowedSaleTypeOptions } from './redux';
import * as fields from './fields';
import SelectedBidStatus from '../SelectedBidStatus';

const BidTypeInput = ({ packageId, selectedBid }) => {
  const { setValue } = useFormContext();
  const disabled = selectedBid.status !== SelectedBidStatus.New;
  const options = useGetAllowedSaleTypeOptions(packageId, disabled);

  const onChange = _ => {
    setValue(fields.jurisdictionsSelectionTypeFieldName, '');
    setValue(fields.applicableJurisdictionsFieldName, []);
  };

  return (
    <StandardOptionAutoCompleteFormField
      id='bid-type-input'
      name={fields.bidTypeFieldName}
      options={options}
      onValueChange={onChange}
      required
      label="Bid Type"
      fullWidth
      disabled={disabled}
    />
  )
};

BidTypeInput.propTypes = {
  packageId: PropTypes.string.isRequired,
  selectedBid: PropTypes.shape().isRequired
}

export default BidTypeInput;