import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import * as packageMaintenanceCommands from './intellectual-property/maintenance/redux/actions'
import * as security from './infrastructure/security';
import { loginRequest, msalConfig } from "./authConfig";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const ipPackageTag = 'IpPackage';
const usersTag = 'Users';
const userLogsTag = 'UserLog';

const Jurisdictions = 'Jurisdictions';
const IpPackages = 'IpPackages';
const IpPackage = 'IpPackage';
const IpPackagePackFile = 'IpPackagePackFile';
const IpPackagePictureFile = 'IpPackagePictureFile';
const RegisteredInterests = 'RegisteredInterests';
const Bid = 'Bid';
const RegisterInterest = 'RegisterInterest';
const NatureOfInterest = 'NatureOfInterest';
const Bids = 'Bids';
const BidHistory = 'BidHistory';
const Users = 'Users';
const ExportUsersCsv = 'ExportUsers/csv';

const POST = 'POST';
const DELETE = 'DELETE';

const baseQuery = fetchBaseQuery({ 
  baseUrl,
  prepareHeaders: async (headers) => {
    const token = await security.acquireAccessToken(loginRequest, msalConfig);
    headers.set(security.AuthorizationHeaderName, security.formatBearerToken(token));    
    return headers;
  }
});

export const getIpPackagePackFile = async (packageId) => {
  const token = await security.acquireAccessToken(loginRequest, msalConfig);
  return axios({
      method: 'get',
      url: `${baseUrl}${IpPackagePackFile}/${packageId}`,
      responseType: 'blob',
      headers: { [security.AuthorizationHeaderName]: security.formatBearerToken(token) }
  });
}

export const getIpPackagePictureFile = async (packageId) => {
  const token = await security.acquireAccessToken(loginRequest, msalConfig);
  return axios({
      method: 'get',
      url: `${baseUrl}${IpPackagePictureFile}/${packageId}`,
      responseType: 'blob',
      headers: { [security.AuthorizationHeaderName]: security.formatBearerToken(token) }
  });
}

export const getUsersCsvFile = async () => {
  const token = await security.acquireAccessToken(loginRequest, msalConfig);
  return axios({
      method: 'get',
      url: `${baseUrl}${ExportUsersCsv}`,
      responseType: 'blob',
      headers: { [security.AuthorizationHeaderName]: security.formatBearerToken(token) }
  });
}

export const ipApi = createApi({
  reducerPath: 'ip',
  baseQuery: baseQuery,
  refetchOnFocus: true,  
  tagTypes: [ipPackageTag],
  endpoints: (builder) => ({
    getJurisdictions: builder.query({
      query: () => Jurisdictions
    }),
    getPackages: builder.query({
      query: () => IpPackages,
      providesTags: [ipPackageTag],
    }),
    getViewablePackageById: builder.query({
      query: (id) => ({ url: `${IpPackage}/${id}/view` }),
      providesTags: [ipPackageTag],
    }),
    getMaintainablePackageById: builder.query({
      query: (id) => ({ url: `${IpPackage}/${id}/maintain` }),
      providesTags: [ipPackageTag],
    }),
    savePackage: builder.mutation({
      query: (ipPackage) =>  ({
        url: IpPackage,
        method: POST,
        body: ipPackage,
      }),
      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(packageMaintenanceCommands.packageSaved(query.id));
      },
      invalidatesTags: [ipPackageTag],
    }),
    deletePackage: builder.mutation({
      query: ({ id }) => ({
        url: `${IpPackage}/${id}`,
        method: DELETE
      }),
      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(packageMaintenanceCommands.packageDeleted(query.id));
      },
      invalidatesTags: [ipPackageTag],
    }),
    uploadPackFile: builder.mutation({
      query: (file) => ({
        url: IpPackagePackFile,
        method: POST,
        body: file,
      }),
      invalidatesTags: [ipPackageTag],
    }),
    uploadPictureFile: builder.mutation({
      query: (file) => ({
        url: IpPackagePictureFile,
        method: POST,
        body: file,
      }),
      invalidatesTags: [ipPackageTag],
    }),   
    getRegisteredInterests: builder.query({
      query: () => RegisteredInterests,
      providesTags: [ipPackageTag],
    }),
    registerInterest: builder.mutation({
      query: ({ id }) => ({
        url: `${RegisterInterest}/${id}`,
        method: POST
      }),
      invalidatesTags: [ipPackageTag],
    }),
    registerNatureOfInterest: builder.mutation({
      query: ({ id, ...interest }) => ({
        url: `${NatureOfInterest}/${id}`,
        method: POST,
        body: interest,
      }),
      invalidatesTags: [ipPackageTag],
    }),
    getBids: builder.query({
      query: () => Bids,
      providesTags: [ipPackageTag],
    }),
    getBidHistory: builder.query({
      query: (bidId) => `${BidHistory}/${bidId}`,
      providesTags: [ipPackageTag],
    }),
    saveBid: builder.mutation({
      query: (bid) => ({
        url: Bid,
        method: POST,
        body: bid,
      }),
      invalidatesTags: [ipPackageTag],
    }),
    withdrawBid: builder.mutation({
      query: ({ packageId, bidType }) => ({
        url: `${Bid}/${packageId}/${bidType}`,
        method: DELETE
      }),
      invalidatesTags: [ipPackageTag],
    }),
    getUsers: builder.query({
      query: () => Users,
      providesTags: [usersTag],
    }),
    getUserById: builder.query({
      query: (id) => `${Users}/${id}`,
      providesTags: [usersTag],
    }),
    getUserLogs: builder.query({
      query: (id) => `${Users}/${id}/logs`,
      providesTags: (id) => [{ type: userLogsTag, id }],
    }),
    activateUser: builder.mutation({
      query: ({ id }) => ({
        url: `${Users}/${id}/activate`,
        method: POST
      }),
      invalidatesTags: [usersTag, userLogsTag],
    }),
    deactivateUser: builder.mutation({
      query: ({ id }) => ({
        url: `${Users}/${id}/deactivate`,
        method: POST
      }),
      invalidatesTags: [usersTag, userLogsTag],
    }),
  }),
})

export const { 
  useGetJurisdictionsQuery,
  useGetPackagesQuery,
  useGetViewablePackageByIdQuery,
  useGetMaintainablePackageByIdQuery,
  useSavePackageMutation,
  useUploadPackFileMutation,
  useUploadPictureFileMutation,
  useDeletePackageMutation,
  useGetRegisteredInterestsQuery,
  useRegisterInterestMutation,
  useRegisterNatureOfInterestMutation,
  useGetBidsQuery,
  useGetBidHistoryQuery,
  useSaveBidMutation,
  useWithdrawBidMutation,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUserLogsQuery,
  useActivateUserMutation,
  useDeactivateUserMutation
} = ipApi;