
import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { useMsal } from "@azure/msal-react";
import DrawerItem from './infrastructure/layout/pages/drawer/DrawerItem';
import Divider from '@mui/material/Divider';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InterestsIcon from '@mui/icons-material/Interests';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import { maintainPackagesPath, bidsPath, usersPath, registeredinterestsPath } from './AppRoutes';
import { logout } from './users/authentication/helpers';
import { Roles } from './users/authentication/types';
import UserRoleVisibility from './users/authentication/UserRoleVisibility';

const AppDrawerItems = () => {    
    const navigate = useNavigate();
    const msal = useMsal();

    return (   
        <React.Fragment>
            <UserRoleVisibility roles={[Roles.PLATFORM_ADMIN, Roles.ORGANIZATION_STANDARD]}>
                <DrawerItem
                    key={0}
                    text="IP Packages"
                    icon={<InventoryIcon />}
                    onClick={() => navigate('/', { replace: true })}
                />
            </UserRoleVisibility>
            <UserRoleVisibility roles={[Roles.PLATFORM_ADMIN]}>
                <DrawerItem
                    key={1}
                    text="Maintain IP Packages"
                    icon={<EditIcon />}
                    onClick={() => navigate(maintainPackagesPath, { replace: true })}
                />
            </UserRoleVisibility>
            <UserRoleVisibility roles={[Roles.PLATFORM_ADMIN]}>
                <DrawerItem
                    key={2}
                    text="All IP Interests"
                    icon={<InterestsIcon />}
                    onClick={() => navigate(registeredinterestsPath, { replace: true })}
                />
            </UserRoleVisibility>
            <UserRoleVisibility roles={[Roles.PLATFORM_ADMIN]}>
                <DrawerItem
                    key={3}
                    text="All IP Bids"
                    icon={<ShoppingCartIcon />}
                    onClick={() => navigate(bidsPath, { replace: true })}
                />
            </UserRoleVisibility>
            <UserRoleVisibility roles={[Roles.PLATFORM_ADMIN]}>
                <DrawerItem
                    key={4}
                    text="Users"
                    icon={<PersonIcon />}
                    onClick={() => navigate(usersPath, { replace: true })}
                />
            </UserRoleVisibility>
            <UserRoleVisibility roles={[Roles.PLATFORM_ADMIN]}>
                <Divider />
            </UserRoleVisibility>
            <DrawerItem
                key={100}
                text="Logout"
                icon={<LogoutIcon />}
                onClick={() => logout(msal)}
            />
        </React.Fragment>
    );
}

export default AppDrawerItems;

