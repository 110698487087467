import * as React from 'react';
import PropTypes from 'prop-types';
import Section from '../infrastructure/layout/sections/Section';
import SectionTitle from '../infrastructure/text/SectionTitle';
import { getUsersCsvFile, useGetUsersQuery } from '../api';
import UsersListFilter from './UsersListFilter'
import UsersList from './UsersList'
import ActionButton from '../infrastructure/ActionButton';
import FileDownload from 'js-file-download';

const handleFileDownLoad = () => {
  getUsersCsvFile()
    .then(response => FileDownload(response.data, "Users.csv"))
    .catch(error => console.log(error))
}

const UsersSection = ({ selectedId }) => {
  const response = useGetUsersQuery();
  return (
    <Section>
      <SectionTitle>Users List</SectionTitle>
      <UsersListFilter />
      <UsersList
        selectedId={selectedId}
        response={response}
      />
      <ActionButton id="export-users-button" onClick={() => handleFileDownLoad()}>Export to CSV</ActionButton>
    </Section>
  );
};

UsersSection.propTypes = {
  selectedId: PropTypes.string
};

export default UsersSection;