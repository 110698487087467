import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { setRequiredTextOnValidationIfRequired } from './validation';

const TextFormField = ({
  id,
  name,
  required,
  validation,
  ...rest
}) => {
  setRequiredTextOnValidationIfRequired(validation, required);

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <TextField
          id={id}
          name={name}
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          error={!!error}
          helperText={error ? error.message : ''}
          inputRef={ref}
          {...rest}
        />
      )}
    />
  );
}

TextFormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validation: PropTypes.shape()
};

TextFormField.defaultProps = {
  required: false,
  validation: {}
};

export default TextFormField;