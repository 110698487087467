import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ActionButton from '../../infrastructure/ActionButton';
import { clearTags } from './redux/slices';
const TagFilterPopup = ({ isOpen, onClose, children }) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      {children}
      <DialogActions>
        <ActionButton
          id="clear-selected-tags-button"
          onClick={() => dispatch(clearTags())}
        >
            Clear
        </ActionButton>
        <ActionButton
          id="close-select-tags-button"
          onClick={onClose}
        >
            Close
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}

TagFilterPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default TagFilterPopup;