
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'

const SelectableChip = ({ id, icon, label, selected, disabled, onClick }) =>(
    <Chip
        id={id}
        icon={icon}
        label={label}
        color={selected ? "primary" : "default"}
        disabled={disabled}
        onClick={onClick}
    />
);

SelectableChip.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

SelectableChip.defaultProps = {
    selected: false,
    onClick: null
}
export default SelectableChip;