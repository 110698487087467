
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../../../infrastructure/layout/tabs/TabPanel';
import MaintainIpPackageLargeGridItem from '../MaintainIpPackageLargeGridItem';
import SaleTypesConfigurationEditor from './SaleTypesConfigurationEditor';
import ReadOnlySaleTypesVisibility from './ReadOnlySaleTypesVisibility';
import ReadOnlySaleTypesInformation from './ReadOnlySaleTypesInformation';

const MaintainIpPackageItemSaleTypesConfigurationTab = ({ packageId, currentTabIndex }) => {
  return (
    <TabPanel
      currentIndex={currentTabIndex}
      index={1}
    >
      <MaintainIpPackageLargeGridItem>
        <ReadOnlySaleTypesVisibility packageId={packageId}>
          <ReadOnlySaleTypesInformation />
        </ReadOnlySaleTypesVisibility>
        <SaleTypesConfigurationEditor packageId={packageId} />
      </MaintainIpPackageLargeGridItem>
    </TabPanel>
  );
}

MaintainIpPackageItemSaleTypesConfigurationTab.propTypes = {
  packageId: PropTypes.string.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
};

export default MaintainIpPackageItemSaleTypesConfigurationTab;