
import * as React from 'react';
import PropTypes from 'prop-types';
import ProprietorChip from '../infrastructure/chips/ProprietorChip';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';

const IpPackageItemProprietor = ({ item }) => (
  <ItemCloudItem key={item.proprietor}><ProprietorChip label={item.proprietor} /></ItemCloudItem>
);
  
IpPackageItemProprietor.propTypes = {
  item: PropTypes.shape().isRequired
};

export default IpPackageItemProprietor;