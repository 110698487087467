
import * as React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Visible from '../Visible'

const MinimumWidthVisibilty = ({ children, minimumWidth }) => {
    const isAboveMinimum = useMediaQuery(`(min-width:${minimumWidth})`);
    return (
        <Visible isVisible={isAboveMinimum}>{children}</Visible>
    );
}
  
MinimumWidthVisibilty.propTypes = {
  children: PropTypes.node.isRequired,
  minimumWidth: PropTypes.string.isRequired
};

export default MinimumWidthVisibilty;