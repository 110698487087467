
import * as React from 'react';
import PropTypes from 'prop-types';
import DataQueryCard from '../infrastructure/data/DataQueryCard';

const IpPackageItemDataCard = ({ queryResponse, data, children }) =>  (
  <DataQueryCard
    queryResponse={queryResponse}
    data={data}
    fields={[{
      isTitle: true,
      dataLookup: data => data.name
    }, {
      dataLookup: data => data.description
    }]}
  >
    {children}
  </DataQueryCard>
);

IpPackageItemDataCard.propTypes = {
  queryResponse: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};


export default IpPackageItemDataCard;