import { useEffect } from 'react';
import { MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { b2cPolicies, msalConfig } from "../../authConfig";
import PropTypes from 'prop-types';

const msalInstance = new PublicClientApplication(msalConfig);

const AuthenticationProvider = ({ children }) => {
    useEffect(() => {
        const callbackId = msalInstance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_FAILURE) {
            console.error(event.error);
          }
    
          if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
            if (event?.payload && event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile) {
              window.alert("Profile has been edited successfully. \nPlease sign-in again.");
              return msalInstance.logoutRedirect();
            }
          }
        });
    
        return () => {
          if (callbackId) {
            msalInstance.removeEventCallback(callbackId);
          }
        };
      }, []);

    return (
    <MsalProvider instance={msalInstance}>
        {children}
    </MsalProvider>
    );
};

AuthenticationProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthenticationProvider;