
import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../infrastructure/Visible';
import {  useGetHasNatureOfInterestSubmitted } from './redux';

const HasNatureOfInterestSubmittedVisibility = ({ packageId, disabled, children }) =>  {
  const hasInterest = useGetHasNatureOfInterestSubmitted(packageId);
  return (
    <Visible isVisible={hasInterest && !disabled}>
      {children}
    </Visible>
  );
}

HasNatureOfInterestSubmittedVisibility.propTypes = {
  packageId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default HasNatureOfInterestSubmittedVisibility;

