import IpPackageStatuses from '../../IpPackageStatuses';
import dayjs from 'dayjs';

export const getActiveFromFieldName = (status) => `${status}PackageStatusActiveFrom`;

export const setStatusesFormField = (field, value, setValue) => {
    setValue(
        getActiveFromFieldName(field),
        value ? new dayjs(new Date(value)) : ''
    );
}

export const setStatusesFormFields = (draft, preview, active, closing, closed, setValue) => {
    setStatusesFormField(IpPackageStatuses.Draft, draft, setValue);
    setStatusesFormField(IpPackageStatuses.Preview, preview, setValue);
    setStatusesFormField(IpPackageStatuses.Active, active, setValue);
    setStatusesFormField(IpPackageStatuses.Closing, closing, setValue);
    setStatusesFormField(IpPackageStatuses.Closed, closed, setValue);
}

export const getStatusesFromForm = (formValues) => ({
    draft: formValues[getActiveFromFieldName(IpPackageStatuses.Draft)],
    preview: formValues[getActiveFromFieldName(IpPackageStatuses.Preview)],
    active: formValues[getActiveFromFieldName(IpPackageStatuses.Active)],
    closing: formValues[getActiveFromFieldName(IpPackageStatuses.Closing)],
    closed: formValues[getActiveFromFieldName(IpPackageStatuses.Closed)]
});
