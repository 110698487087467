import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

const AppLocalisation = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    {children}
  </LocalizationProvider>
);

AppLocalisation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLocalisation;