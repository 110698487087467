
import * as React from 'react';
import Grid from '@mui/material/Grid';
import VerticalLayout from '../../../infrastructure/layout/VerticalLayout';
import SaleTypes from '../../maintenance/sale-types/SaleTypes';
import SaleTypeConfigurationEditorItem from './SaleTypeConfigurationEditorItem';
import PropTypes from 'prop-types';
import { useGetSaleTypesConfigurationIsReadOnly } from '../redux/selectors';

const SaleTypesConfigurationGrid = ({ children }) => <Grid container spacing={2}>{children}</Grid>

const SaleTypesConfigurationEditor = ({ packageId }) => {
  const isPackageReadOnly = useGetSaleTypesConfigurationIsReadOnly(packageId);

  return (
    <VerticalLayout>
      <SaleTypesConfigurationGrid>
        <SaleTypeConfigurationEditorItem saleType={SaleTypes.Assignment} disabled={isPackageReadOnly} />
        <SaleTypeConfigurationEditorItem saleType={SaleTypes.ExclusiveLicense} disabled={isPackageReadOnly} />
        <SaleTypeConfigurationEditorItem saleType={SaleTypes.NonExclusiveLicense} disabled={isPackageReadOnly} />
      </SaleTypesConfigurationGrid>
    </VerticalLayout>
  )
};

SaleTypesConfigurationEditor.propTypes = {
  packageId: PropTypes.string.isRequired
};

export default SaleTypesConfigurationEditor;