
import * as React from 'react';
import { useParams } from 'react-router';
import AuthenticatedPage from '../../users/authentication/AuthenticatedPage';
import Sections from '../../infrastructure/layout/sections/Sections';
import AppDrawerItems from '../../AppDrawerItems';
import { useGetMaintainablePackageByIdQuery } from '../../api';
import MaintainIpPackagesSection from './MaintainIpPackagesSection';
import MaintainIpPackageItemSection from './MaintainIpPackageItemSection';
          
const MaintainIpPackagePage = () => {
  let { id } = useParams();
  const getPackageQueryResponse = useGetMaintainablePackageByIdQuery(id);
  
  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
        <MaintainIpPackagesSection selectedId={id} />
        <MaintainIpPackageItemSection
          packageId={id}
          getPackageQueryResponse={getPackageQueryResponse}/>
      </Sections>
    </AuthenticatedPage>
  );
}

export default MaintainIpPackagePage;