import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { ChevronLeft } from '@mui/icons-material';

const BackButton = ({ id, disabled, onClick, ...rest}) => (
    <Button
        id={id}
        fullWidth
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        {...rest}
    >
        <ChevronLeft />&nbsp;Back
    </Button>
);

BackButton.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

BackButton.defaultProps = {
    disabled: false,
};

export default BackButton;