import * as React from 'react';
import AuthenticatedPage from '../../../users/authentication/AuthenticatedPage';
import Sections from '../../../infrastructure/layout/sections/Sections';
import Section from '../../../infrastructure/layout/sections/Section';
import SectionTitle from '../../../infrastructure/text/SectionTitle';
import RegisteredInterestsListFilter from './RegisteredInterestsListFilter'
import RegisteredInterestsList from './RegisteredInterestsList'
import { useGetRegisteredInterestsQuery } from '../../../api';
import AppDrawerItems from '../../../AppDrawerItems';

const RegisteredInterestsListPage = () => {
  const response = useGetRegisteredInterestsQuery();

  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
        <Section>
          <SectionTitle>IP Package Registered Interest List</SectionTitle>
          <RegisteredInterestsListFilter />
          <RegisteredInterestsList response={response}></RegisteredInterestsList>
        </Section>
      </Sections>
    </AuthenticatedPage>
  );
}

export default RegisteredInterestsListPage;