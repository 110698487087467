
import * as React from 'react';
import PropTypes from 'prop-types';
import Visible from '../../infrastructure/Visible';
import { useGetHasInterestRegistered } from './redux';

const HasInterestVisibility = ({ packageId, children }) => {
  const hasInterest = useGetHasInterestRegistered(packageId);
  return (
    <Visible isVisible={hasInterest}>
      {children}
    </Visible>
  );
}

HasInterestVisibility.propTypes = {
  packageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HasInterestVisibility;

