
import * as React from 'react';
import PropTypes from 'prop-types';
import VerticalLayout from '../../infrastructure/layout/VerticalLayout';
import SaveIpPackageButton from './SaveIpPackageButton';
import DeleteIpPackageButton from './DeleteIpPackageButton';
import NotNewVisibility from './NotNewVisibility';

const MaintainIpPackageItemSectionButtons = ({ packageId, isNew }) =>  {
  return (
    <VerticalLayout>
      <SaveIpPackageButton />
      <NotNewVisibility isNew={isNew}>
        <DeleteIpPackageButton packageId={packageId} />  
      </NotNewVisibility>
    </VerticalLayout>
  );
}

MaintainIpPackageItemSectionButtons.propTypes = {
  packageId: PropTypes.string.isRequired,  
  isNew: PropTypes.bool
};

MaintainIpPackageItemSectionButtons.defaultProps = {
  isNew: false
};

export default MaintainIpPackageItemSectionButtons;