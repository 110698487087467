
import * as React from 'react';
import PropTypes from 'prop-types';
import FileDownload from 'js-file-download';
import ActionButton from '../infrastructure/ActionButton'
import DownloadIcon from '@mui/icons-material/Download';
import { getIpPackagePackFile } from '../api';

const handleFileDownLoad = (packageId, name) => {
  getIpPackagePackFile(packageId)
    .then(response => FileDownload(response.data, name))
    .catch(error => console.log(error))
}

const IpPackageItemDownloadButton = ({ item }) =>  {
  return (
    <ActionButton 
      onClick={() => handleFileDownLoad(item.id, item.packFileName)}
    >
      {<DownloadIcon />}&nbsp;Download Info Sheets (Zip file) 
    </ActionButton>
  );
}

IpPackageItemDownloadButton.propTypes = {
  item: PropTypes.shape()
};

export default IpPackageItemDownloadButton;

