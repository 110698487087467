import * as React from 'react';
import { fieldShape, fieldDefaults } from './field'
import CardTitle from '../text/CardTitle';
import CardField from '../text/CardField';

const DataCardField = ({ data, field }) => {
  const content = field.dataLookup(data);
  if (field.isTitle) {
    return (
      <CardTitle>{content}</CardTitle>
    )
  } else {
    return (
      <CardField text={content} />
    )
  }
};

DataCardField.propTypes = fieldShape;
DataCardField.defaultProps = fieldDefaults;

export default DataCardField;