export const boolToYesNo = (from) => from === true ? 'Yes' : 'No';
export const removeWhitespace = (from) => from.replace(/\s/g, "");

export const htmlEncode = (from) => {
    return from.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;');
  }

export const commaSeparatedToList = (from) => {
    if(!from) {
        return[];
    }

    if(from.length === 0) {
        return[];
    }

    if(!from.includes(',')) {
        return [from];
    }

    return from.split(',');
}

export const makeListCommaSeparated = (from) => from.join(',');
export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
export const max = (value1, value2) => value1 > value2 ? value1 : value2;
export const toLocaleNumericFormat = num => num ? new Intl.NumberFormat().format(num) : num;