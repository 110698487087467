
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const LinkedInChip = ({ label }) => <Chip icon={<LinkedInIcon />} label={label} />;

LinkedInChip.propTypes = {
    label: PropTypes.string.isRequired
};

export default LinkedInChip;