
import * as React from 'react';
import PropTypes from 'prop-types';
import ItemCloudItem from '../../../infrastructure/layout/ItemCloudItem';
import NewOrDirtyVisibility from '../NewOrDirtyVisibility';
import NotNewOrDirtyVisibility from '../NotNewOrDirtyVisibility';
import IpPackageFileUploadNotPossibleAlert from './IpPackageFileUploadNotPossibleAlert';
import IpPackageFileNameTag from './IpPackageFileNameTag';
  
const IpPackageFileNameSection = ({ packageId, fileName, onUploadFile, onFileUploaded, acceptedFileTypes, isNew }) => {
  return (
    <React.Fragment>
      <ItemCloudItem>
        <NewOrDirtyVisibility isNew={isNew}>
          <IpPackageFileUploadNotPossibleAlert />
        </NewOrDirtyVisibility>
        <NotNewOrDirtyVisibility isNew={isNew}>
          <IpPackageFileNameTag
            packageId={packageId}
            fileName={fileName}
            onUploadFile={onUploadFile}
            onFileUploaded={onFileUploaded}
            acceptedFileTypes={acceptedFileTypes}
          />
        </NotNewOrDirtyVisibility>
      </ItemCloudItem>
    </React.Fragment>
  );
}


IpPackageFileNameSection.propTypes = {
  packageId: PropTypes.string.isRequired,  
  fileName: PropTypes.string,
  onUploadFile: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func,
  acceptedFileTypes: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default IpPackageFileNameSection;