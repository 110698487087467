import * as React from 'react';
import PropTypes from 'prop-types';
import JurisdictionChip from './JurisdictionChip';
import { commaSeparatedToList, makeListCommaSeparated } from '../infrastructure/helpers';

const isJurisdictionSelected = (field, jurisdiction) => 
  commaSeparatedToList(field.value).includes(jurisdiction);

const addOrRemoveSelectedJurisdiction = (selectedJurisdictions, jurisdiction) => {
  if(selectedJurisdictions.includes(jurisdiction)) {
    selectedJurisdictions.splice(selectedJurisdictions.indexOf(jurisdiction), 1);
  } else {
    selectedJurisdictions.push(jurisdiction);
  }
}

const selectJurisdiction = (field, jurisdiction) => {
  const selectedJurisdictions = commaSeparatedToList(field.value);
  addOrRemoveSelectedJurisdiction(selectedJurisdictions, jurisdiction);
  field.onChange(makeListCommaSeparated(selectedJurisdictions));
}

const JurisdictionItem = ({ saleType, field, jurisdiction, disabled }) => {
  return (
    <JurisdictionChip
      id={`${saleType}-jurisdiction-${jurisdiction}`}
      key={jurisdiction}
      label={jurisdiction}
      selected={isJurisdictionSelected(field, jurisdiction)}
      disabled={disabled}
      onClick={() => selectJurisdiction(field, jurisdiction)}
    />
  );
}

JurisdictionItem.propTypes = {
  saleType: PropTypes.string.isRequired,
  field: PropTypes.shape().isRequired,
  jurisdiction: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default JurisdictionItem;