import * as React from 'react';
import PropTypes from 'prop-types';
import ErrorDisplay from '../ErrorDisplay';

const HttpErrorDisplay = ({ queryResponse }) => {
  if(!queryResponse.error) {
    return null;
  }
  if(!queryResponse.error.status) {
    return null;
  } 
  return (
    <ErrorDisplay title={ queryResponse.error.status.toString() } />
  );
}

HttpErrorDisplay.propTypes = {
  queryResponse: PropTypes.shape().isRequired
};

export default HttpErrorDisplay;