
import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form'
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const FormValidationTabs = ({
  currentTabIndex,
  onChangeTab,
  tabs,
}) => { 
  const { errors } = useFormState();

  const tabContainsValidationErrors = (errors, tab) => {
    for(let fieldIndex = 0; fieldIndex < tab.validationFields.length; fieldIndex++) {
      const errorFields = Object.keys(errors);
      const field = tab.validationFields[fieldIndex];
      if(errorFields.includes(field)) {
        return true;
      }
    }
  }

  const renderTabs = (tabs) => {
    if(tabs.length === 0) {
      return null;
    }
    return tabs.map((tab) => {
      if(!tabContainsValidationErrors(errors, tab)) {
        return (<Tab id={tab.id} key={tab.id} label={tab.label} />);
      } else {
        return (<Tab id={tab.id} key={tab.id} icon={<ErrorIcon fontSize='small'/>} iconPosition="end" label={tab.label} />);
      }
    }); 
  }

  return (
    <Tabs
      value={currentTabIndex}
      onChange={(_event, newValue) => onChangeTab(newValue)}
    >
      {renderTabs(tabs)}
    </Tabs>
  );
}

FormValidationTabs.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default FormValidationTabs;