
import * as React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '../infrastructure/layout/tabs/TabPanel';
import ItemCloud from '../infrastructure/layout/ItemCloud';
import IpPackageItemStatus from './IpPackageItemStatus';
import IpPackageItemProprietor from './IpPackageItemProprietor';
import IpPackageItemTags from './IpPackageItemTags';
import IpPackageItemDownloadButton from './IpPackageItemDownloadButton';

const IpPackageItemGeneralTab = ({ currentTabIndex, item }) =>  (
  <TabPanel
      currentIndex={currentTabIndex}
      index={0}
  >
      <ItemCloud>
        <IpPackageItemStatus item={item}/>
        <IpPackageItemProprietor item={item}/>
        <IpPackageItemTags item={item} />
      </ItemCloud>
      <IpPackageItemDownloadButton item={item} />
  </TabPanel>
);

IpPackageItemGeneralTab.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  item: PropTypes.shape()
};

export default IpPackageItemGeneralTab;