import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { setRequiredTextOnValidationIfRequired } from './validation';

const optionValueSelector = option => option.value ? option.value : '';

const StandardOptionAutoCompleteFormField = ({
  id,
  name,
  fullWidth,
  options,
  defaultValue,
  onValueChange,
  required,
  validation,
  disabled,
  ...rest
}) => {
  setRequiredTextOnValidationIfRequired(validation, required);

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const valueOption = options.find(option => optionValueSelector(option) === value);        
        
        if(valueOption) {
          value = valueOption;
        } else {
          value = null
        }
        
        return (
          <Autocomplete
            fullWidth={fullWidth}
            disabled={disabled}
            disableClearable
            disablePortal
            defaultValue={defaultValue}
            value={value}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={option => option.label}
            onChange={(_event, value) => {
              const optionValue = optionValueSelector(value);
              if(onValueChange) {
                onValueChange(optionValue);
              }
              onChange(optionValue);
            }}
            onBlur={onBlur}
            renderInput={(params) => (
              <TextField
                id={id}
                name={name}
                required={required}
                {...rest}
                {...params}
                error={!!error}
                helperText={error ? error.message : ''}
                inputRef={ref}
              />
            )}
          />
        )
      }}
    />
  );
}

StandardOptionAutoCompleteFormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  defaultValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  onValueChange: PropTypes.func,
  required: PropTypes.bool,
  validation: PropTypes.shape(),
  disabled: PropTypes.bool
};

StandardOptionAutoCompleteFormField.defaultProps = {
  fullWidth: false,
  required: false,
  validation: {},
  disabled: false
};


export default StandardOptionAutoCompleteFormField;