
import * as React from 'react';
import PropTypes from 'prop-types';
import TelephoneChip from '../infrastructure/chips/TelephoneChip';
import ItemCloudItem from '../infrastructure/layout/ItemCloudItem';

const UserTelephone = ({ user }) => (
  <ItemCloudItem key={user.proprietor}><TelephoneChip label={user.telephoneNumber ?? 'None'} /></ItemCloudItem>
);
  
UserTelephone.propTypes = {
  user: PropTypes.shape().isRequired
};

export default UserTelephone;