import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { ipApi } from '../../../api'
import { getFilteredData } from '../../../users/filtering/redux/selectors';

const selectRegisteredInterestsResult = ipApi.endpoints.getRegisteredInterests.select();

export const getRegisteredInterests = createSelector(
  selectRegisteredInterestsResult,
  interestsResult => {
    const result = interestsResult?.data ?? { data: [] };
    return result.data;
  }
);

const getFilteredRegisteredInterests  = createSelector(
  getRegisteredInterests,
  getFilteredData,
  (interests, filteredInterests) => filteredInterests ?? interests
);


export const useGetRegisteredInterests = () => useSelector(getRegisteredInterests);
export const useGetFilteredRegisteredInterests = () => useSelector(getFilteredRegisteredInterests);