import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const PageToolBarLeftContent = ({ children }) =>(
  <Box component="div" sx={{ flexGrow: 1 }}>
    {children}
  </Box>
);


PageToolBarLeftContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageToolBarLeftContent;