import * as React from 'react';

import CentreBox from '../../infrastructure/layout/CentreBox';
import SpacedItem from '../../infrastructure/layout/SpacedItem';
import TagFilterItem from './TagFilterItem';
import { useGetSelectedTagsFromAssets } from './redux/selectors';
import SelectTagsButton from './SelectTagsButton';

const renderItems = tags => { 
  if(tags.length === 0) {
    return null;
  }
  return tags.map(
    (tag, index) => (
      <SpacedItem key={index}>
        <TagFilterItem tag={tag} key={index} />
      </SpacedItem>
    )
  );
}

const MinimalTagCloud = ({ onSelectTags }) => {
  let tags = useGetSelectedTagsFromAssets();
   
  return (
    <CentreBox>
      {renderItems(tags)}
      <SpacedItem>
        <SelectTagsButton onClick={onSelectTags}/>
      </SpacedItem>
    </CentreBox>
  );
}

export default MinimalTagCloud;