
import * as React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import JurisdictionTypeOptions from './JurisdictionTypeOptions';
import SelectJurisdictionsInput from './jurisdiction-selection/SelectJurisdictionsInput';
import SelectJurisdictionsVisibility from './jurisdiction-selection/SelectJurisdictionsVisibility';
import Grid from '@mui/material/Grid';

const SaleTypeConfigurationGridItem = ({ children }) => <Grid item xs={12}>{children}</Grid>

const SaleTypeConfigurationEditorItem = ({ saleType, disabled }) => {
  return (
    <React.Fragment>
      <SaleTypeConfigurationGridItem>
        <JurisdictionTypeOptions saleType={saleType} disabled={disabled} />
      </SaleTypeConfigurationGridItem>
      <SelectJurisdictionsVisibility saleType={saleType}>
        <SaleTypeConfigurationGridItem>
          <SelectJurisdictionsInput saleType={saleType} disabled={disabled} />
        </SaleTypeConfigurationGridItem>
      </SelectJurisdictionsVisibility>
      <SaleTypeConfigurationGridItem>
        <Divider />
      </SaleTypeConfigurationGridItem>
    </React.Fragment>
  );
}

SaleTypeConfigurationEditorItem.propTypes = {
  saleType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SaleTypeConfigurationEditorItem;