import * as React from 'react';
import PropTypes from 'prop-types';
import CentreBox from '../../layout/CentreBox';
import FilterTextField from './FilterTextField';

const StandardTextFilter = ({ label, onFilterSet }) => {
    const [filterText, setFilterText] = React.useState('');
   
    const changeHandler = input =>  { 
        setFilterText(input.target.value);
        onFilterSet(input.target.value);
    }
    
    return (
        <CentreBox>
            <FilterTextField
                label={label}
                onChange={changeHandler}
                text={filterText}
            />
        </CentreBox>
    );
};

StandardTextFilter.propTypes = {
    label: PropTypes.string.isRequired,
    onFilterSet: PropTypes.func.isRequired,
};

export default StandardTextFilter;