const JurisdictionSelectionTypes = {
    All: 'all',
    Selected: 'selected'
}

export const parseJurisdictionSelectionType = (rawType) => rawType.toLowerCase();

export const getJurisdictionSelectionTypeLabel = (type) => {
    switch(type) {
        case JurisdictionSelectionTypes.All: 
            return 'All';
        case JurisdictionSelectionTypes.Selected: 
            return 'Selected';
        default:
            return `UNKNOWN jurisdiction selection type: ${type}` 
    }
}

export const jurisdictionSelectionTypeToServiceFormat = getJurisdictionSelectionTypeLabel;

export const getAllJurisdictionsSelectionTypeOptions = () => {
    const result = [];
    const keys = Object.keys(JurisdictionSelectionTypes);
    for (let key of keys) {
        result.push({
            label: getJurisdictionSelectionTypeLabel(JurisdictionSelectionTypes[key]),
            value: JurisdictionSelectionTypes[key]
        });
    }
    return result;
}

export default JurisdictionSelectionTypes;