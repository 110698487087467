import * as React from 'react';
import PropTypes from 'prop-types';
import ItemCloud from '../infrastructure/layout/ItemCloud';
import CentreBox from '../infrastructure/layout/CentreBox';
import SpacedItem from '../infrastructure/layout/SpacedItem';
import SelectedJurisdictions from './SelectedJurisdictions';
import SelectJurisdictionButton from './SelectJurisdictionButton';
import Visible from '../infrastructure/Visible';

const SelectedJurisdictionsCloud = ({ saleType, field, onSelectJurisdictions, allowMoreSelection, areAllJurisdictionsSelected, disabled }) => {
  return (
    <ItemCloud>
      <CentreBox>
        <SelectedJurisdictions
          saleType={saleType}
          field={field}
          disabled={disabled}
        />
        <Visible isVisible={allowMoreSelection || areAllJurisdictionsSelected}>
          <SpacedItem>
            <SelectJurisdictionButton
              saleType={saleType}
              field={field}
              onClick={onSelectJurisdictions}
              disabled={disabled}
              areAllTagsSelected={areAllJurisdictionsSelected}
            />
          </SpacedItem>
        </Visible>
      </CentreBox>
    </ItemCloud>
  );
}

SelectedJurisdictionsCloud.propTypes = {
  saleType: PropTypes.string,
  field: PropTypes.shape().isRequired,
  onSelectJurisdictions: PropTypes.func.isRequired,
  areAllJurisdictionsSelected: PropTypes.bool,
  allowMoreSelection: PropTypes.bool,
  disabled: PropTypes.bool
};

SelectedJurisdictionsCloud.defaultProps = {
  saleType: 'unknown',
  allowMoreSelection: true,
  disabled: false
}

export default SelectedJurisdictionsCloud;