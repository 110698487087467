import * as React from 'react';
import AuthenticatedPage from '../../../users/authentication/AuthenticatedPage';
import Sections from '../../../infrastructure/layout/sections/Sections';
import BidsListSection from './BidsListSection';
import AppDrawerItems from '../../../AppDrawerItems';

const BidsListPage = () => (
  <AuthenticatedPage drawerItems={<AppDrawerItems />}>
    <Sections>
      <BidsListSection />
    </Sections>
  </AuthenticatedPage>
);

export default BidsListPage;