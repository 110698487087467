import * as React from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';

const AppBar = ({ children }) => <MuiAppBar position="absolute" open={true}>{children}</MuiAppBar>;

AppBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppBar;