import * as React from 'react';
import PropTypes from 'prop-types';
import DataQueryTable from '../infrastructure/data/DataQueryTable'; 
import { useGetResetPageCount } from './redux/selectors';
import { useGetFilteredAssets } from './filtering/redux/selectors';

const IpPackageList = ({ selectedId, response, onPackageSelected }) => {
  const data = useGetFilteredAssets();
  const resetPageCount = useGetResetPageCount();
  return (
    <DataQueryTable
      id="ip-package-list"
      queryResponse={response}
      data={data}
      onRowClick={(row) => onPackageSelected(row.id)}
      selectedRowId={selectedId}
      resetPageCount={resetPageCount}
      columns={([{
          name: 'Name',
          rowLookup: row => row.name
        },
        {
          name: 'Proprietor',
          rowLookup: row => row.proprietor
        },
      ])}
    />
  );
}

IpPackageList.propTypes = {
  selectedId: PropTypes.string,
  response: PropTypes.object.isRequired,
  onPackageSelected: PropTypes.func.isRequired
};

export default IpPackageList;