import * as React from 'react';
import { useParams } from 'react-router';
import AuthenticatedPage from '../../../users/authentication/AuthenticatedPage';
import Sections from '../../../infrastructure/layout/sections/Sections';
import AppDrawerItems from '../../../AppDrawerItems';
import BidsListSection from '../list/BidsListSection';
import BidHistorySection from './BidHistorySection';

const BidHistoryPage = () => {
  let { id } = useParams();
  return (
    <AuthenticatedPage drawerItems={<AppDrawerItems />}>
      <Sections>
        <BidsListSection />
        <BidHistorySection bidId={id}/>
      </Sections>
    </AuthenticatedPage>
  );
}

export default BidHistoryPage;