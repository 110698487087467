
import * as React from 'react';
import PropTypes from 'prop-types';
import JurisdictionChip from './JurisdictionChip'
import { commaSeparatedToList } from '../infrastructure/helpers';

const areAnyTagsSelected = (field) => 
  commaSeparatedToList(field.value).length > 0;

const SelectJurisdictionButton = ({ saleType, field, disabled, areAllTagsSelected, onClick }) =>  {
  return (
    <JurisdictionChip
      id={`${saleType}-select-jurisdiction-button`}
      label={areAllTagsSelected ? 'Select/De-select' : (areAnyTagsSelected(field) ? 'Select more...' : 'Select Jurisdictions')}
      selected={true}
      disabled={disabled}
      onClick={onClick}
    />
  );
}

SelectJurisdictionButton.propTypes = {
  saleType: PropTypes.string.isRequired,
  field: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  areAllTagsSelected: PropTypes.bool
};

export default SelectJurisdictionButton;

