
import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { maintainPackagePath, bindIdToPath } from '../../AppRoutes'
import Section from '../../infrastructure/layout/sections/Section';
import SectionTitle from '../../infrastructure/text/SectionTitle';
import IpPackageList from '../IpPackageList';
import TagFilterSection from '../filtering/TagFilterSection';
import { useGetPackagesQuery } from '../../api';
import AddNewIpPackageButton from './AddNewIpPackageButton';

const MaintainIpPackagesSection = ({ selectedId }) => { 
  const response = useGetPackagesQuery();
  let navigate = useNavigate();
  return (
    <Section>
      <SectionTitle>Maintain IP Packages</SectionTitle>
      <TagFilterSection />
      <IpPackageList
        selectedId={selectedId}
        response={response}
        onPackageSelected={(id) => navigate(bindIdToPath(maintainPackagePath, id))}
      />
      <AddNewIpPackageButton />
    </Section>
  );
}


MaintainIpPackagesSection.propTypes = {
  selectedId: PropTypes.string
};

export default MaintainIpPackagesSection;