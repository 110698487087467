import * as React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

export const primary = '#fec707';
const primaryText = '#3b4251';
const secondaryText = '#666666';
const allWhite = '#FFFFFF';

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    info: {
      main: primary,
    },
    secondary: {
      main: '#484848',
    },
    text: {
      primary: primaryText,
      secondary: secondaryText
    }
  },
  typography: { 
    fontFamily: ['"Open Sans", Arial, Helvetica, sans-serif'],
    h2: {
      fontFamily: ['Raleway, Arial, Helvetica, sans-serif'],
      fontSize: '37.66px',
      color: primary,
      textAlign: 'center'
    },
    h3: {
      fontFamily: ['Raleway, Arial, Helvetica, sans-serif'],
      fontSize: '18px',
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: '700'
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: secondaryText,
          cursor: 'pointer'
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: allWhite
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ['"PT Sans", Arial, Helvetica, sans-serif'],
          fontSize: '12px',
          color: allWhite
        }
      },
      variants: [
        {
          props: { 
            variant: 'dialog'
          },
          style: {
            color: primaryText,
          },
        },
      ],
    },
    MuiButtonRoot: {
      
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: secondaryText
        },
      },
    },
  },
});

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    { children }
  </ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;