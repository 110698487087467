import * as React from 'react';
import { maxIntRule, positiveNumberRule } from '../../../infrastructure/forms/validation';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { bidAmountFieldName } from './fields';

const AmountInput = () => {
  return (
    <TextFormField
      name={bidAmountFieldName}
      label="Amount"
      variant="outlined"
      required={true}
      validation={{
        validate: {
          positiveNum: positiveNumberRule,
          maxInt: maxIntRule
        }
      }}
      fullWidth
    />
  )
};

export default AmountInput;