import * as React from 'react';
import { maxLengthRule } from '../../../infrastructure/forms/validation';
import TextFormField from '../../../infrastructure/forms/TextFormField';
import { ipPackageDescriptionFieldName } from './fields';

const IpPackageDescriptionInput = () => { 
  return (
    <TextFormField  
      id="ip-package-description-field"
      name={ipPackageDescriptionFieldName}
      label="Package Description"
      variant="outlined"
      multiline
      required
      fullWidth
      validation={{
        maxLength: maxLengthRule(5000)
      }}
    />
  );
}

export default IpPackageDescriptionInput;