import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { ipApi } from '../../../../api'

const selectJurisdictionsResult = ipApi.endpoints.getJurisdictions.select();

export const getJurisdictions = () => createSelector(
  selectJurisdictionsResult,
  jurisdictionResult => jurisdictionResult?.data ?? []
);

export const useGetJurisdictions = () => useSelector(getJurisdictions());
