
import * as React from 'react';
import PropTypes from 'prop-types';
import VerticalLayout from '../../../infrastructure/layout/VerticalLayout';
import RegisterInterestInformation from './RegisterInterestInformation';
import RegisterInterestList from './RegisterInterestList';
import RegisteredInterestEditButton from './RegisteredInterestEditButton';

const RegisterInterestViewSection = ({ packageId, onEdit }) => {
  return (
    <VerticalLayout id="nature-of-interest-view-section">
      <RegisterInterestInformation />
      <RegisterInterestList packageId={packageId} />
      <RegisteredInterestEditButton onClick={onEdit} />
    </VerticalLayout>
  );
}

RegisterInterestViewSection.propTypes = {
  packageId: PropTypes.string,
  onEdit: PropTypes.func.isRequired
};

export default RegisterInterestViewSection;