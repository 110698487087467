import * as React from 'react';
import PropTypes from 'prop-types';
import WithdrawBidButton from './WithdrawBidButton';
import BidBackButton from './BidBackButton';
import HorizontalLayout from '../../../infrastructure/layout/HorizontalLayout';

const WithdrawBidButtons = ({ packageId, selectedBid, onBack }) => {
    return (
        <HorizontalLayout>
            <WithdrawBidButton
                packageId={packageId}
                selectedBid={selectedBid}
                onWithdrawn={onBack}
            />
            <BidBackButton onBack={onBack} />
        </HorizontalLayout>
    );
}

WithdrawBidButtons.propTypes = {
    packageId: PropTypes.string.isRequired,
    selectedBid: PropTypes.shape().isRequired,
    onBack: PropTypes.func.isRequired,
};

export default WithdrawBidButtons;