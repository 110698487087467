
import * as React from 'react';
import PropTypes from 'prop-types';
import Section from '../../infrastructure/layout/sections/Section';
import VerticalLayout from '../../infrastructure/layout/VerticalLayout';
import MaintainIpPackageLargeGridItem from './MaintainIpPackageLargeGridItem';
import MaintainIpPackageItemTabs from './MaintainIpPackageItemTabs';
import IpPackageMaintenanceForm from './IpPackageMaintenanceForm';
import IpPackageMaintenanceFormLoader from './IpPackageMaintenanceFormLoader';
import MaintainIpPackageItemGrid from './MaintainIpPackageItemGrid';
import MaintainIpPackageItemGeneralTab from './general/MaintainIpPackageItemGeneralTab';
import MaintainIpPackageItemSaleTypesConfigurationTab from './sale-types/MaintainIpPackageItemSaleTypesConfigurationTab';
import MaintainIpPackageItemStatusesTab from './statuses/MaintainIpPackageItemStatusesTab';
import MaintainIpPackageItemPacksTab from './files/packs/MaintainIpPackageItemPacksTab';
import MaintainIpPackageItemPicturesTab from './files/pictures/MaintainIpPackageItemPicturesTab';
import MaintainIpPackageItemSectionButtons from './MaintainIpPackageItemSectionButtons';

const MaintainIpPackageItemSection = ({ packageId, isNew, getPackageQueryResponse }) =>  {
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  return (
    <Section id="maintain-ip-package-item-section">
      <VerticalLayout>
        <IpPackageMaintenanceForm
          packageId={packageId}
          getPackageQueryResponse={getPackageQueryResponse}
        >          
          <IpPackageMaintenanceFormLoader packageId={packageId}>
            <MaintainIpPackageItemGrid>
              <MaintainIpPackageLargeGridItem>
                <MaintainIpPackageItemTabs
                  currentTabIndex={currentTabIndex}
                  onChangeTab={setCurrentTabIndex}
                />
              </MaintainIpPackageLargeGridItem>
              <MaintainIpPackageItemGeneralTab currentTabIndex={currentTabIndex} />
              <MaintainIpPackageItemSaleTypesConfigurationTab packageId={packageId} currentTabIndex={currentTabIndex} />
              <MaintainIpPackageItemStatusesTab currentTabIndex={currentTabIndex} />
              <MaintainIpPackageItemPacksTab
                packageId={packageId}
                isNew={isNew}
                currentTabIndex={currentTabIndex}
              />
              <MaintainIpPackageItemPicturesTab
                packageId={packageId}
                isNew={isNew}
                currentTabIndex={currentTabIndex}
              />
              <MaintainIpPackageLargeGridItem>
                <MaintainIpPackageItemSectionButtons
                  packageId={packageId}
                  isNew={isNew}
                />
              </MaintainIpPackageLargeGridItem>
            </MaintainIpPackageItemGrid>
          </IpPackageMaintenanceFormLoader>
        </IpPackageMaintenanceForm>
      </VerticalLayout>
    </Section>
  );
}

MaintainIpPackageItemSection.propTypes = {
  packageId: PropTypes.string.isRequired,  
  isNew: PropTypes.bool,  
  getPackageQueryResponse: PropTypes.shape(),
};

MaintainIpPackageItemSection.defaultProps = {
  isNew: false
};

export default MaintainIpPackageItemSection;